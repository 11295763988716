
.prod-header-content .alert {
    margin-bottom: 0 !important;
}

#product-detail {


    .product-detail-nav {
        padding-top: 35px;
    }

    .product-detail-nav p {
        margin-bottom: 0;
    }

    .breadcrumbs a {
        color: $black;
        font: {
            family: $main-font;
            weight: 400;
            size: 16px;
        }
        line-height: 22px;
        color: $button-dark;
    }

    @media (max-width: 767px) {
        .breadcrumbs > p {
            margin-bottom: 0;
        }
    }

    .breadcrumbs a:last-child {
        font-weight: bold;
    }

    .product-info-row {

        > div:first-child > .row {
            height: 100%;

            img {
                object-fit: cover;
                border-radius: 4px;
            }
        }
    }

    @media (max-width: 767px) {
        .product-info-row {
            padding-top: 0 !important;
        }
    }

    .product-detail-price {
        /*font-family: aviano-serif, serif !important;
        font-style: normal;
        font-weight: 400;
        color: $black !important;
        font-size: 24px;*/
        font: {
            family: $main-font;
            weight: 700;
            size: 24px;
        }
        line-height: 34px;
        color: $main-color;
    }


    .product-detail-description {
        padding-top: 25px;
        max-width: 564px;
    }

    .product-detail-description, .product-detail-description * {
        font: {
            family: $main-font;
            weight: 400;
            size: 16px;
        }
        line-height: 22px;
        color: $black;

    }

    .product-detail-variant-label {
        font: {
            family: $main-font;
            weight: 400;
            size: 16px;
        }
        line-height: 22px;
        color: $main-color;
    }

    .colorvariants .variant {
        max-width: calc(74px + 1rem);
    }

    .colorvariants .variant:hover .image-container,
    .colorvariants .variant:focus .image-container,
    .colorvariants .variant.active-variant .image-container {
        border: 2px solid $main-color;
    }

    .colorvariants .variant img {
        width: 70px;
        height: 100px;
        object-fit: cover;
    }

    .colorvariants .variant .opstelling {
        display: block;
        background: $button-dark;
        color: $white;
        text-align: center;
        font-size: 12px;
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .qty-add-to-cart .order-btn {
        border-radius: 4px;
        background: #CC0000;
        border: 2px solid #CC0000;
        width: 100%;
        color: #FFF;
        text-align: center;
        font-family: $font4;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .qty-add-to-cart .order-btn:hover,
    .qty-add-to-cart .order-btn:focus {
        background: #121212;
        border: 2px solid #121212;
    }

    .qty-add-to-cart .order-btn p {
        margin-bottom: 0;
        font-size: 18px;
        line-height: 21px;
    }

    .qty-add-to-cart .order-btn svg {
        margin-left: 5px;
    }

    .qty-add-to-cart .order-btn img {
        margin-right: 7px;
    }


    .qty-group {
        border: 1px solid black !important;
        border-radius: 0 !important;

        input {
            color: $black !important;
            font-family: aviano-serif, serif;
        }
    }

    .productgallerymainimg {
        padding-left: 0 !important;
        padding-right: 0;
    }

    .image-container-shadow {
        filter: drop-shadow(1px 314px 126px rgba(0, 0, 0, 0.01)) drop-shadow(0px 177px 106px rgba(0, 0, 0, 0.03)) drop-shadow(0px 79px 79px rgba(0, 0, 0, 0.04)) drop-shadow(0px 20px 43px rgba(0, 0, 0, 0.05)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.05));
    }

    #lightgallery {
        margin-top: 30px !important;
    }

    .product-info {
        background: var(--white);
        /*box-shadow: 1px 314px 126px rgba(0, 0, 0, 0.01), 0px 177px 106px rgba(0, 0, 0, 0.03), 0px 79px 79px rgba(0, 0, 0, 0.04), 0px 20px 43px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05);*/
        /*padding: 40px;*/
        padding-top: 20px;
        padding-right: 15px;
        padding-left: 25px;
        padding-bottom: 0px;

        .product-title {
            /*font-family: aviano-serif, serif !important;*/
            /*font-family: $main-font2 !important;
            font-style: normal;
            font-weight: 400;
            color: $brown !important;*/
            font: {
                family: $main-font;
                weight: 400;
                size: 42px;
            }
            line-height: 59px;
            color: $black;
            margin-top: 0;
            margin-bottom: 0 !important;
        }

        .product-brand {
            margin-top: 0 !important;
        }

        .qty-group input {
            min-width: unset;
            max-width: unset;
            width: 100%;
        }

        @media (min-width: 960px) and (max-width: 1088px) {
            .qty-add-to-cart-max div {
                max-width: unset;
                flex: unset;

                .qty-group {
                    width: 70%;
                    text-align: center;
                }

                .butn.order-btn {
                    width: 70%;
                }
            }
        }

        @media (min-width: 1089px) {
            .qty-add-to-cart-max div.col-8 {
                padding-left: 8px;
            }
        }

        @media (max-width: 462px) {
            .qty-add-to-cart-max {
                flex-direction: column;
                max-width: 100%;
                margin: auto;

                > div {
                    max-width: 100%;
                }
            }
        }

    }

    @media (min-width: 960px) {
        .category-details {
            padding-top: 120px !important;
        }

        .product-info-row + .category-details {
            padding-top: 40px !important;
            padding-bottom: 60px;

        }

    }



    .product-info-row + .category-details > * {
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }



    @media (min-width: 1200px) and (max-width: 1500px) {
        > .container {
            padding-left: 30px;
            padding-right: 30px;
        }
    }

    @media (min-width: 768px) and (max-width: 1199px) {
        > .container {
            max-width: 1200px;
            padding-left: 30px;
            padding-right: 30px;
        }
    }


    @media (max-width: 959px) {
        .category-details {
            padding-top: 80px !important;
        }
       /* .product-info {
            padding: 40px 0px;
        }*/
    }

    @media (max-width: 767px) {
        > .container {
            max-width: none;
            padding-left: 30px;
            padding-right: 30px;
        }

/*        .product-info {
            padding: 40px 15px;
        }*/

    }

    .detailed-fabric-image-row {
        height: 100%;

        > div {
            padding-left: 0;
            padding-right: 0;
        }

        .detailed-fabric-image {
            object-fit: cover;
        }
    }

    .product-square {

        .product-info {

            .product-title {
                color: $black !important;
            }
        }
    }

    .related-products {
        padding-left: 0;
        padding-right: 0;

        .to-webshop-btn {
            display: flex !important;
            margin: 10px auto 110px auto;
            font-size: 16px;
        }

        #related-products {
            padding-top: 20px !important;

            .new-content {
                padding-left: 0;
                padding-right: 0;

                .container {
                    padding-left: 0;
                    padding-right: 0;
                }

                .related-products {

                    .uk-slider-container {

                        .uk-slidenav-previous,
                        .uk-slidenav-next {
                            background: none;
                            color: $black;
                        }

                        .uk-slidenav-previous {
                            transform: translateX(40%) translateY(0);
                        }

                        .uk-slidenav-next {
                            transform: translateX(-40%) translateY(0);
                        }
                    }
                }
            }
        }
    }
}


.related-products-section {
    /*background: rgba(244, 237, 229, 0.5);*/
}


.related-products-section h2 {
    text-align: center;
    display: inline-block;
    margin: 0 auto;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    font-family: $font1;
    font-weight: 400;
    font-size: 24px;
    line-height: 34px;
    text-transform: uppercase;
    color: #121212;
}

/*
.related-products-section h2:after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: -10px;
    width: calc(100% + 10px);
    height: 3px;
    background-image: linear-gradient(to right, transparent 50%, #f2c1a1 50%);
    background-size: 22px 100%;
}*/


.related-products-section .uk-slider {
    /*max-width: 96%;*/
    max-width: calc(100% - 96px);
    margin-left: auto;
    margin-right: auto;
}


.related-products-section .uk-slider-items {
    margin-left: 0;
}

.related-products-section .uk-slider-items > div {
    padding-left: 8px;
    padding-right: 8px;
}



.related-products-section .home-offer-slider-desktop {
    padding-top: 40px;
}


.related-products-section .uk-position-center-left-out,
.related-products-section .uk-position-center-right-out {
    background: #CC0000;
    padding: 12px 17px;
    border-radius: 4px;
}


.related-products-section .uk-position-center-left-out svg,
.related-products-section .uk-position-center-right-out svg,
.related-products-section .uk-position-center-left-out svg polyline,
.related-products-section .uk-position-center-right-out svg polyline {
    color: $white;
}


.product-tags span {
    background-color: #F1F0F6;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 140.62%;
    color: #000;
    display: inline-block;
    padding: 6px 10px;
    margin-right: 7px;
}

#product-detail {
    /*padding-bottom: 60px;*/
    padding-bottom: 153px;
}




@media (max-width: 959px) {
   /* #product-detail .product-info-row {
        display: grid;
        grid-template-rows: repeat(2, auto);
        grid-template-columns: repeat(4, 1fr);

        .product-main-img {
            grid-row: 2 / 3;
            grid-column: 1 / 5;
            max-width: unset;
        }

        .product-info {
            grid-row: 1 / 2;
            grid-column: 1 / 4;
            max-width: unset;
        }

        .detailed-fabric-image-div {
            grid-row: 1 / 2;
            grid-column: 4 / 5;
            max-width: unset;
        }
    }*/
}





@media (max-width: 959px) {

    #product-detail {
        /*padding-bottom: 20px;*/
    }

    #product-detail .product-info-row {
        /*  display: grid;
          grid-template-rows: repeat(2, auto);
          grid-template-columns: repeat(4, 1fr);*/

        .product-main-img {
            /*grid-row: 2 / 3;
            grid-column: 1 / 5;*/
            max-width: unset;
        }

        .product-info {
            /*grid-row: 1 / 2;
            grid-column: 1 / 4;*/
            max-width: unset;
        }

        .detailed-fabric-image-div {
            /*grid-row: 1 / 2;
            grid-column: 4 / 5;*/
            max-width: unset;
        }
    }
}

@media (min-width: 876px) {
    .detail-tabs-mobile {
        display: none;
    }
}

@media (max-width: 875px) {

    .detail-tabs {
        width: 100%;
        padding-top: 35px;
    }

    .detail-tabs-desktop {
        display: none;
    }

    #product-detail .product-info-row .product-main-img {
        flex: 0 0 100%;
        max-width: 100%;
    }

    #product-detail .product-info-row .product-info {
        flex: 0 0 100%;
        max-width: 100%;
        /*padding: 0;*/
        padding: 0px 15px;
        margin-top: 40px;
    }

    .product-main-inner-content {
        margin-top: 40px;
    }
}

@media (max-width: 767px) {
    #product-detail .product-info-row {

        .product-main-img {
            /*grid-row: 2 / 3;
            grid-column: 1 / 4;*/
            max-width: unset;

            .productgallerymainimg {
                height: 100%;
            }
        }

        .product-info {
            /*grid-row: 1 / 2;
            grid-column: 1 / 5;*/
            max-width: unset;
        }

        .detailed-fabric-image-div {
            /*grid-row: 2 / 3;
            grid-column: 4 / 5;*/
            max-width: unset;
        }
    }
}




@media (min-width: 768px) {
    .related-products-section {
        padding-top: 40px;
        padding-bottom: 0px;
    }
}

@media (max-width: 767px) {

    .alert .btn {
        width: 100%;
        display: inline-block;
        max-width: 100%;
        margin-left: 0px !important;
        margin-top: 20px !important;
    }

    .product-main-img .main-image {
        margin-top: 40px;
    }

    #product-detail .product-info-row {

        .product-main-img {
            grid-row: 2 / 3;
            grid-column: 1 / 4;
            max-width: unset;

            .productgallerymainimg {
                height: 100%;
            }
        }

        .product-info {
            grid-row: 1 / 2;
            grid-column: 1 / 5;
            max-width: unset;
        }

        .detailed-fabric-image-div {
            grid-row: 2 / 3;
            grid-column: 4 / 5;
            max-width: unset;
        }
    }
}



