.main-categories-filter {
    .main-category-item {
        text-decoration: none !important;
        position: relative;
            transition: all 300ms ease;
        h2 {
            position: relative;
            z-index: 2;
            max-width: 140px;
            color: $light-font-color;
            font-size: 30px;
            font-weight: 700;
            transition: all 300ms ease;
        }
        &:before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $dark-bg-color;
            opacity: .8;
        }
        &:after {
            position: absolute;
            z-index:3;
            content: "";
            bottom:0;
            left: 0;
            height: 3px;
            width: 0;
            transition: all 300ms ease;
            display: block;
            background-color: $main-color;
        }
        &:hover, &.active {
            &:after {
                width: 100%;
            }
            h2 {
                color: $main-color;
            }
        }
    }
}

.all-cat-filter {
    margin-top: 1.5rem !important;
}


.category-filter {

    .category-filter-item-lvl-1, .category-filter-item  {
        color: $font-color;
        transition: all 300ms ease;
        text-decoration: none !important;
        h3 {
            font-size: 22px;
            font-weight: 700;
            color: $font-color;
            transition: all 300ms ease;
        }
        &:hover, &.active {
            color: $brown;
            h3 {
                color: $main-color;
            }
        }
    }
    .category-filter-item {

        > div {
            position: relative;
        }

        > div svg {
            position: absolute;
            top: 50%;
            right: 0%;
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }

        .subcategories-list {
            margin: 0;
            list-style: none;
            padding-left: 0px;
            padding-top: 10px;
            padding-bottom: 10px;

            li.sub-cat {
                padding-left: 10px;
            }

            li.sub-cat +  li.sub-cat {
                padding-top: 10px;
            }

            li.sub-cat a {
                display: inline-block;
            }
        }
    }

}

.category-header {
    position: relative;
    background: {
        color: $pastel-1;
    }
    &:before {
        position: absolute;
        z-index: 1;
        width: 45%;
        height: 100%;
        bottom: 0;
        left: 0;
        color: $pastel-1-accent;
        content: "";
        background-image: url(/images/w.svg);
        background-size: 45%;
        background-position: bottom left;
        background-repeat: no-repeat;
        opacity: .20;
    }
    h1 {
        position: relative;
        z-index: 2;
        display: inline-block;
        font-size: 48px;
        font-weight: 900;
        position: relative;
    }
}

.category-detail {
    position: relative;
    /*overflow-x: hidden;*/

    .category-detail-main {

        max-width: 1230px;

        #overview-products .product-square .product-info {
            /*padding: 10px 0 15px 0 !important;*/
            padding: 15px 0px 0px 0px !important;
            .product-price, .product-price {
                font: {
                    color: #121212;
                    family: $font1;
                    weight: 400;
                    size: 16px;
                }
                line-height: 22px;
            }
            .product-price, .product-price * {
                /*font-family: $main-font;*/
                /*font-family: 'Calibri', sans-serif;*/
                font: {
                    color: #121212;
                    family: $font1;
                    weight: 400;
                    size: 15px;
                }
                line-height: 22px;

            }

            .product-title,
            .productpricespan {
                font-size: 16px !important;
                color: #121212;
                font-family: $font4;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }

        .category-filters-container {

            h4 {
                /*font-family: $main-font;*/
                /*font-family: $main-font2;
                color: $brown;
                font-weight: 400;
                margin-bottom: 0 !important;
                padding-bottom: 0;*/
                font-family: "Roboto", sans-serif;
                font-weight: 700;
                font-size: 18px;
                line-height: 25px;
                color: #272727;

                > div {

                    > div:first-child {
                        /*font-family: $main-font2;
                        color: $brown;
                        font-weight: 400;*/
                        font-family: "Roboto", sans-serif;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 25px;
                        color: #272727;
                    }

                    > div:last-child {
                        display: none;
                    }
                }
            }

            .category-filter {

                .category-filter-item > div {
                    flex-wrap: inherit;
                    gap: 10px;
                }

                .category-filter-item[aria-expanded="true"] > div > svg {
                    transform: rotate(180deg);
                    transition: 0.25s ease-in-out;
                }

                .category-filter-item[aria-expanded="false"] > div > svg {
                    transform: rotate(0deg);
                    transition: 0.25s ease-in-out;
                }

                a.main-cat {
                    /*font-family: $main-font3;
                    font-size: 20px;
                    line-height: 42px;*/
                    font-family: "Roboto", sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    color: #272727;
                }

                .sub-cat > a  {
                    padding-left: 0;
                    padding-right: 0;

                    > div {
                        /*font-family: $main-font;*/
                        font-family: 'Calibri', sans-serif;
                        line-height: 18px;
                        font-size: 16px;
                    }
                }

                .filter-form label {
                    font-family: $main-font;
                    font-size: 18px;
                    line-height: 22px;
                    font-weight: 300;
                }
            }

          /*  .category-filter:last-child {

                > div:nth-last-child(1),
                > div:nth-last-child(2) {
                    display: none;
                }
            }*/
        }
    }

    @include media-breakpoint-up(lg) {
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 50%;
            height: calc(100%);
            transform: translateX(-25%);
            opacity: .6;
            background: {
                image: url("/images/bg-trending-left.png");
                repeat: repeat-y;
            }
            display: none;
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            width: 50%;
            height: calc(100%);
            transform: translateX(20%);
            opacity: .6;
            background: {
                image: url("/images/bg-trending-right.png");
                repeat: repeat-y;
                position: right;
            }
            display: none;
        }
    }
}

.category-detail-header {
    /*background: $pastel-1;*/
    position: relative;
    z-index: 0;
    padding-top: 40px;
    padding-bottom: 20px;
    position: relative;
    max-width: 1230px;
}

.category-detail-header::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 15px;
    right: 15px;
    height: 1px;
    width: calc(100% - 30px);
    background: #CCC;
}

.category-detail-header::before {
    /*content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 50%;
    height: calc(100%);
    transform: translateX(0%);
    opacity: .2;
    background: {
        image: url("/images/w.svg");
        repeat: no-repeat;
        size: contain;
    }*/
}


.category-detail-header h1 {
    position: relative;
    z-index: 0;
    display: inline-block;
    color: #121212;
    font-family: $font2;
    font-size: 48px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: none;
}

.category-detail-header h1 span {
    color: #121212;
    font-family: $font4;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.category-detail-header h1::before {
    /*content: "";
    position: absolute;
    bottom: -10px;
    left: -10px;
    width: calc(100% + 10px);
    height: 3px;
    background-image: linear-gradient(to right, transparent 50%, $pastel-2 50%);
    background-size: 22px 100%;*/
}


.home-contact-section .home-contact {

    .title-top {
        font-family: $main-font;
    }

    .title-bottom {
        font-family: $main-font;
    }
}


.category-detail-main-nav {
    margin-bottom: 35px;
}

.category-detail-main-nav * {
    font: {
        family: $main-font;
        weight: 400;
        size: 16px;
    }
    line-height: 22px;
    color: $button-dark;
}

.category-detail-main-nav strong {
    font-weight: 700;
}

.category-detail-main .filter-title,
.category-detail-main .category-filter h4 {
    font: {
        family: $font4;
        weight: 700;
        size: 20px;
    }
    line-height: 25px;
    color: $black;
}

.category-detail-main .category-filter hr {
    border-top: 1px solid $pastel-3;
}

.category-detail-main .category-filter-item a {
    font: {
        family: $main-font;
        weight: 400;
        size: 16px;
    }
    line-height: 22px;
    color: $black;

}

.category-detail-main .category-filter-item a.active {
    font: {
        family: $font4;
        weight: 700;
        size: 16px;
    }
    line-height: 22px;
    color: #CC0000;
}

.category-detail-main .products-count {
    font: {
        family: $main-font;
        weight: 400;
        size: 18px;
    }
    line-height: 25px;
    color: $black;
    padding-bottom: 15px;
    position: relative;
    z-index: 0;
}

.category-detail-main .products-count::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 1px;
    background: $pastel-3;
}

.category-detail-main #overview-products {
    padding-top: 0px;
    margin-right: 0px;
}

.category-detail-main #overview-products > div {
    /*border: 1px solid rgba(204, 204, 204, 0.50);*/
    padding: 1rem !important;
    margin: 0 !important;
}

/*
.category-detail-main #overview-products > div:nth-child(1),
.category-detail-main #overview-products > div:nth-child(3n+4) {
    border-left: 0px;
}

.category-detail-main #overview-products > div:nth-child(9n+1),
.category-detail-main #overview-products > div:nth-child(9n+2),
.category-detail-main #overview-products > div:nth-child(9n+3) {
    border-top: 0px;
}

 */

.category-detail-main #overview-products > div:nth-child(9n+1) {
    padding-left: 0 !important;
    padding-top: 0 !important;
    border-bottom: 1px solid rgba(204, 204, 204, 0.50);
}

.category-detail-main #overview-products > div:nth-child(9n+2) {
    padding-top: 0 !important;
    border-left: 1px solid rgba(204, 204, 204, 0.50);
    border-bottom: 1px solid rgba(204, 204, 204, 0.50);
    border-right: 1px solid rgba(204, 204, 204, 0.50);
}

.category-detail-main #overview-products > div:nth-child(9n+3) {
    padding-top: 0 !important;
    padding-right: 0 !important;
    border-bottom: 1px solid rgba(204, 204, 204, 0.50);
}

.category-detail-main #overview-products > div:nth-child(9n+4) {
    padding-left: 0 !important;
}

.category-detail-main #overview-products > div:nth-child(9n+5) {
    border-left: 1px solid rgba(204, 204, 204, 0.50);
    border-right: 1px solid rgba(204, 204, 204, 0.50);
}

.category-detail-main #overview-products > div:nth-child(9n+6) {
    padding-right: 0 !important;
    border-bottom: 1px solid rgba(204, 204, 204, 0.50);
}

.category-detail-main #overview-products > div:nth-child(9n+7) {
    padding-left: 0 !important;
    border-top: 1px solid rgba(204, 204, 204, 0.50);
    padding-bottom: 0 !important;
}

.category-detail-main #overview-products > div:nth-child(9n+8) {
    border-left: 1px solid rgba(204, 204, 204, 0.50);
    border-right: 1px solid rgba(204, 204, 204, 0.50);
    border-top: 1px solid rgba(204, 204, 204, 0.50);
    padding-bottom: 0 !important;
}

.category-detail-main #overview-products > div:nth-child(9n+9) {
    padding-right: 0 !important;
    padding-bottom: 0 !important;
}

.category-detail-main .category-filter-item a:hover,
.category-filter .category-filter-item:hover {
    color: #50676a !important;
    text-decoration: none !important;
}


.category-detail-footer {
    background-color: $pastel-3;
    background-image: url('/images/ideas.svg');
    background-repeat: no-repeat;
    background-size: 350px;
    background-position: 0 -55px;
    height: 240px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}


.category-detail-footer h3 {
    font: {
        family: $main-font;
        weight: 700;
        size: 42px;
    }
    line-height: 59px;
    color: $black;
    max-width: 367px;
    margin-bottom: 0;
}

.category-detail-footer-col2 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}


.category-detail-footer ul {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    list-style: none;
    width: 100%;
    margin-bottom: 0;
}

.category-detail-footer ul li {
    padding-left: 15px;
    padding-right: 15px;
}


.category-detail-footer ul li:nth-child(1) a {
    margin: 0;
    border: none;
    overflow: visible;
    font: inherit;
    color: $white;
    text-transform: none;
    -webkit-appearance: none;
    border-radius: 0;
    display: inline-block;
    box-sizing: border-box;
    padding: 0 30px;
    vertical-align: middle;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    text-decoration: none;
    background-color: $main-color;
    box-shadow: 0 0 15px $pastel-3-accent;
    border-radius: 5px;
    font-size: 18px;
    padding-top: 7px;
    padding-bottom: 7px;
    transition: all 300ms ease-in-out;
    width: 100% !important;
}

.category-detail-footer ul li:nth-child(1) a:hover,
.category-detail-footer ul li:nth-child(1) a:focus {
    background-color: #FFF;
    box-shadow: 0 0 15px $pastel-3-accent;
    color: #000;
}

.category-detail-footer ul li:nth-child(2) a {
    margin: 0;
    border: none;
    overflow: visible;
    font: inherit;
    color: inherit;
    text-transform: none;
    -webkit-appearance: none;
    border-radius: 0;
    display: inline-block;
    box-sizing: border-box;
    padding: 0 30px;
    vertical-align: middle;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    text-decoration: none;
    background-color: #FFF;
    box-shadow: 0 0 15px $pastel-3-accent;
    border-radius: 5px;
    font-size: 18px;
    padding-top: 7px;
    padding-bottom: 7px;
    transition: all 300ms ease-in-out;
    width: 100% !important;
}

.category-detail-footer ul li:nth-child(2) a:hover,
.category-detail-footer ul li:nth-child(2) a:focus {
    background-color: $main-color;
    box-shadow: 0 0 15px $pastel-3-accent;
    color: #FFF;
}

.toggle-filter {
    border-radius: 4px !important;
    margin-bottom: 30px !important;
    border: 1px solid #CC0000 !important;
    background: #CC0000;
    justify-content: center;
    align-items: center;
    color: #FFF;
    text-align: center;
    font-family: $font4;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 100%;
    display: inline-block;
    padding: 11px 20px 13px 20px !important;
    transition: all 300ms ease-in-out;
    cursor: pointer;
}

.toggle-filter:hover,
.toggle-filter:focus {
    border: 1px solid #121212 !important;
    background: #121212 !important;
    color: #FFF;
    text-decoration: none;
}


.category-bottom, .product-bottom {
    position: relative;
    padding-top: 155px;
    padding-bottom: 20px;
    background-color: #CC0000;
}

.category-bottom::before, .product-bottom::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 105px;
    background: url('/images/ruler.jpg');
}

.category-bottom-usps, .product-bottom-usps {
    position: absolute;
    top: 0%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}


.category-bottom-usps > div, .product-bottom-usps > div {
    max-width: 1036px;
    padding-top: 48px;
    padding-bottom: 48px;
    border-radius: 4px;
    background: #FFF;
    /*box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04), 0px 15px 34px 0px rgba(0, 0, 0, 0.04), 0px 61px 61px 0px rgba(0, 0, 0, 0.03), 0px 138px 83px 0px rgba(0, 0, 0, 0.02), 0px 246px 98px 0px rgba(0, 0, 0, 0.01), 0px 384px 108px 0px rgba(0, 0, 0, 0.00);*/
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04), 0px 15px 34px 0px rgba(0, 0, 0, 0.04), 0px 61px 61px 0px rgba(0, 0, 0, 0.03), 0px 138px 83px 0px rgba(0, 0, 0, 0.02), 0px 246px 98px 0px rgba(0, 0, 0, 0.01), 0px 384px 108px 0px rgba(0, 0, 0, 0.00);
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.04)) drop-shadow(0px -19px 42px rgba(0, 0, 0, 0.04)) drop-shadow(0px -77px 77px rgba(0, 0, 0, 0.03)) drop-shadow(0px -173px 104px rgba(0, 0, 0, 0.02)) drop-shadow(0px -308px 123px rgba(0, 0, 0, 0.01)) drop-shadow(0px -482px 135px rgba(0, 0, 0, 0.00));
}


.category-bottom-usps ul, .product-bottom-usps ul {
    list-style: none;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    padding-left: 0px;
    margin-right: -55px;
    margin-bottom: 0px;
}

.category-bottom-usps ul li, .product-bottom-usps ul li {
    position: relative;
    padding-right: 55px;
    color: #121212;
    font-family: $font2;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.category-bottom-usps ul li:nth-child(1), .product-bottom-usps ul li:nth-child(1) {
    padding-left: 65px;
}

.category-bottom-usps ul li:nth-child(2),
.category-bottom-usps ul li:nth-child(3),
.product-bottom-usps ul li:nth-child(2),
.product-bottom-usps ul li:nth-child(3) {
    padding-left: 59px;
}

.category-bottom-usps ul li:nth-child(1)::before,
.product-bottom-usps ul li:nth-child(1)::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: -11px;
    left: 0;
    width: 45px;
    height: 45px;
    background-size: contain;
    background: url('/images/delivery-icon.svg') no-repeat 50% 0%;
}

.category-bottom-usps ul li:nth-child(2)::before,
.product-bottom-usps ul li:nth-child(2)::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: -9px;
    left: 0;
    width: 39px;
    height: 39px;
    background-size: contain;
    background: url('/images/quality-icon.svg') no-repeat 50% 0%;
}

.category-bottom-usps ul li:nth-child(3)::before,
.product-bottom-usps ul li:nth-child(3)::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: -9px;
    left: 0;
    width: 39px;
    height: 39px;
    background-size: contain;
    background: url('/images/personalised-icon.svg') no-repeat 50% 0%;
}

.category-bottom-cta-p1, .product-bottom-cta-p1 {
    color: #FFF;
    text-align: center;
    font-family: $font4;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 10px;
}
.category-bottom-cta-p2, .product-bottom-cta-p2 {
    color: #FFF;
    font-family: $font2;
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-top: 10px;
    margin-bottom: 30px;
}
.category-bottom-cta-p3 a {
    display: inline-block;
    width: 154px;
    padding: 9px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: #FFF;
    color: #CC0000;
    text-align: center;
    font-family: $font1;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}



.product-bottom-cta-p3 a {
    display: inline-block;
    width: 246px;
    padding: 9px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: #FFF;
    color: #CC0000;
    text-align: center;
    font-family: $font1;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

@include media-breakpoint-up(md){
    .collapse.collapse-on-mobile{
        display: block;
        height: auto !important;
        visibility: visible;
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .category-detail .category-detail-main > div > div:first-child {
        padding-left: 0;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .category-detail > .category-detail-main .category-filters-container {
        padding-left: 0;
    }
}



@media (min-width: 768px) {
    .category-detail-main {
        padding-top: 35px;
        padding-bottom: 170px;
    }

    .category-detail-footer ul li {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%;
    }


    .category-detail-filter-left #mobile-filter {
        display: block !important;
    }

}


@media (min-width: 992px) {
    .category-detail-main .col-xl-3 #mobile-filter {
        display: none;
    }

    .category-detail-header .col-lg-7 {
        padding-left: 140px;
    }

}


@media (min-width: 1200px) {
    .category-detail-main .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .category-detail-main .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }


    .category-detail-main #overview-products .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

}



@media (max-width: 1260px) {
    .category-detail-footer-col2 ul li {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .category-detail-footer-col2 ul li + li {
        margin-top: 20px;
    }

}



@media (min-width: 768px) and (max-width: 1199px) {
    .category-detail-main {
        padding-left: 30px;
        padding-right: 30px;
    }
}


@media (max-width: 1199px) {
    .category-detail-main #overview-products > div:nth-child(9n+1) {
        padding-left: 0 !important;
        padding-top: 0 !important;
        border-bottom: 1px solid rgba(204, 204, 204, 0.50);
    }

    .category-detail-main #overview-products > div:nth-child(9n+2) {
        padding-top: 0 !important;
        border-left: 1px solid rgba(204, 204, 204, 0.50);
        border-bottom: 1px solid rgba(204, 204, 204, 0.50);
        border-right: 0px solid rgba(204, 204, 204, 0.50);
        padding-right: 0 !important;
    }

    .category-detail-main #overview-products > div:nth-child(9n+3) {
        padding-top: 1rem !important;
        padding-left: 0 !important;
        padding-right: 1rem !important;
        border-bottom: 1px solid rgba(204, 204, 204, 0.50);
    }

    .category-detail-main #overview-products > div:nth-child(9n+4) {
        border-left: 1px solid rgba(204, 204, 204, 0.50);
        padding-left: 1rem !important;
        border-bottom: 1px solid rgba(204, 204, 204, 0.50);
        padding-right: 0 !important;
    }

    .category-detail-main #overview-products > div:nth-child(9n+5) {
        border-left: 0px solid rgba(204, 204, 204, 0.50);
        border-right: 1px solid rgba(204, 204, 204, 0.50);
        border-bottom: 1px solid rgba(204, 204, 204, 0.50);
        padding-left: 0 !important;
    }

    .category-detail-main #overview-products > div:nth-child(9n+6) {
        padding-right: 0 !important;
        border-bottom: 1px solid rgba(204, 204, 204, 0.50);
    }

    .category-detail-main #overview-products > div:nth-child(9n+7) {
        padding-left: 0 !important;
        border-top: 1px solid rgba(204, 204, 204, 0.50);
        padding-bottom: 1rem !important;
        border-bottom: 1px solid rgba(204, 204, 204, 0.50);
    }

    .category-detail-main #overview-products > div:nth-child(9n+8) {
        border-left: 1px solid rgba(204, 204, 204, 0.50);
        border-right: 0px solid rgba(204, 204, 204, 0.50);
        border-top: 1px solid rgba(204, 204, 204, 0.50);
        padding-bottom: 1rem !important;
        border-bottom: 1px solid rgba(204, 204, 204, 0.50);
        padding-right: 0 !important;
    }

    .category-detail-main #overview-products > div:nth-child(9n+9) {
        padding-right: 0 !important;
        padding-bottom: 0 !important;
        padding-left: 0 !important;
        padding-right: 1rem !important;
        border-right: 1px solid rgba(204, 204, 204, 0.50);
    }
}


@media (max-width: 1199px) {
    .category-detail-filter-left > div.col-xl-9 {
        padding-right: 0px;
    }
}


@media (max-width: 991px) {
    .category-detail .category-detail-header .col-lg-7 {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .category-detail-main .products-count {
        overflow: hidden;
    }


    .category-detail .category-detail-main #overview-products .product-square .product-info > div.col-2 {
        text-align: right;
    }

}



@media (max-width: 939px) {
    .category-bottom-usps {
        top: -15%;
    }

    .product-bottom-usps {
        top: -10%;
    }
}

@media (max-width: 767px) {

    .category-bottom-cta-p1, .product-bottom-cta-p1,
    .category-bottom-cta-p2, .product-bottom-cta-p2 {
        font-size: 24px;
    }

    .category-bottom::before, .product-bottom::before {
        transform: rotate(180deg);
    }

    .category-bottom, .product-bottom {
        padding-top: 255px;
    }

    .category-bottom-usps, .product-bottom-usps {
        top: 25%;
    }

    .category-detail-main #overview-products {
        padding-left: 15px;
        padding-right: 15px;
    }


    .category-detail-filter-left #mobile-filter {
        margin-bottom: 20px;
    }

        /*    .category-detail-main #mobile-filter {
                display: none;
            }*/


    .cat-filter-sec {
        display: none;
    }


    .category-detail-main {
        padding-top: 35px;
        padding-bottom: 65px;
    }

    .category-detail-main-products > div:nth-child(1) {
        padding-left: 15px;
        padding-right: 15px;
    }

    .category-detail-main-products > div:nth-child(2) {
        padding-left: 30px;
        padding-right: 30px;
    }

    .category-detail-main-products > div:nth-child(3) {
        padding-left: 25px;
        padding-right: 25px;
    }


    .category-detail-footer {
        height: auto;
        padding-top: 40px;
        padding-bottom: 40px;
        background-size: 300px;
        background-position: 100% -40px;
    }

    .category-detail-footer-col2 {
        padding-top: 40px;
    }

    .category-detail-footer ul {
        padding-left: 0;
    }

    .category-detail-main {
        padding-bottom: 40px;
    }

    #product-detail {
        padding-bottom: 20px !important;
    }

    .category-bottom-usps > div, .product-bottom-usps > div {
        padding-top: 24px;
        padding-bottom: 24px;
    }

}




@media (max-width: 575px) {
    .category-detail-main #overview-products > div:nth-child(9n+1) {
        padding: 1rem !important;
        border-bottom: 0px solid rgba(204, 204, 204, 0.50);
    }

    .category-detail-main #overview-products > div:nth-child(9n+2) {
        padding-top: 0 !important;
        border-left: 0px solid rgba(204, 204, 204, 0.50);
        border-bottom: 0px solid rgba(204, 204, 204, 0.50);
        border-right: 0px solid rgba(204, 204, 204, 0.50);
        padding-right: 0 !important;
        padding: 1rem !important;
        padding-top: 0 !important;
    }

    .category-detail-main #overview-products > div:nth-child(9n+3) {
        padding-top: 1rem !important;
        padding-left: 0 !important;
        padding-right: 1rem !important;
        border-bottom: 0px solid rgba(204, 204, 204, 0.50);
        padding: 1rem !important;
        padding-top: 0 !important;
    }

    .category-detail-main #overview-products > div:nth-child(9n+4) {
        border-left: 0px solid rgba(204, 204, 204, 0.50);
        padding-left: 1rem !important;
        border-bottom: 0px solid rgba(204, 204, 204, 0.50);
        padding-right: 0 !important;
        padding: 1rem !important;
        padding-top: 0 !important;
    }

    .category-detail-main #overview-products > div:nth-child(9n+5) {
        border-left: 0px solid rgba(204, 204, 204, 0.50);
        border-right: 0px solid rgba(204, 204, 204, 0.50);
        border-bottom: 0px solid rgba(204, 204, 204, 0.50);
        padding-left: 0 !important;
        padding: 1rem !important;
        padding-top: 0 !important;
    }

    .category-detail-main #overview-products > div:nth-child(9n+6) {
        padding-right: 0 !important;
        border-bottom: 0px solid rgba(204, 204, 204, 0.50);
        padding: 1rem !important;
        padding-top: 0 !important;
    }

    .category-detail-main #overview-products > div:nth-child(9n+7) {
        padding-left: 0 !important;
        border-top: 0px solid rgba(204, 204, 204, 0.50);
        padding-bottom: 1rem !important;
        border-bottom: 0px solid rgba(204, 204, 204, 0.50);
        padding: 1rem !important;
        padding-top: 0 !important;
    }

    .category-detail-main #overview-products > div:nth-child(9n+8) {
        border-left: 0px solid rgba(204, 204, 204, 0.50);
        border-right: 0px solid rgba(204, 204, 204, 0.50);
        border-top: 0px solid rgba(204, 204, 204, 0.50);
        padding-bottom: 1rem !important;
        border-bottom: 0px solid rgba(204, 204, 204, 0.50);
        padding-right: 0 !important;
        padding: 1rem !important;
        padding-top: 0 !important;
    }

    .category-detail-main #overview-products > div:nth-child(9n+9) {
        padding-right: 0 !important;
        padding-bottom: 0 !important;
        padding-left: 0 !important;
        padding-right: 1rem !important;
        border-right: 0px solid rgba(204, 204, 204, 0.50);
        padding: 1rem !important;
        padding-top: 0 !important;
    }

    .category-detail-main #overview-products > div > a {
        border-bottom: 1px solid rgba(204, 204, 204, 0.50) !important;
        margin-bottom: 1rem;
    }

    .category-detail-main #overview-products > div + div {
        padding-top: 0 !important;
    }


}



@media (max-width: 575px) {
    .category-detail-main #overview-products {
        padding-left: 0px;
        padding-right: 0px;
    }
}



@media (max-width: 480px) {

    .category-bottom, .product-bottom {
        /*padding-top: 355px;*/
        padding-top: 275px;
    }

    .category-bottom-usps, .product-bottom-usps {
        top: 27.5%;
    }
}
