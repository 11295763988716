ul.menu-main-menu, ul.menu-language-items {


        @extend .row;
        @extend .justify-content-center;
        @extend .h-100;
        @extend .align-items-center;
        margin-bottom: 0;
        padding-left: 0;
        > li {
            @extend .col-auto;
            font: {
                family: $main-font;
                size: 16px;
            }

            position: relative;
            list-style-type: none;
            color: $white;
            &> a, &> a:visited, &> span {
                display: inline-block;
                position: relative;
                height: 100%;
                color: #FFF;
                text-decoration: none !important;
                padding: 12px 3px;
                font-family: $font1;
                font-size: 14px;
                font-weight: 700;
                text-transform: uppercase;
                &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 3px;
                    right: 3px;
                    width: calc(100% - 6px);
                    max-width: calc(100% - 6px);
                    height: 2px;
                    /*background-image: linear-gradient(to right, transparent 50%, $pastel-2 50%);*/
                    transition: all 300ms ease;
                    /*background-size: 22px 100%;*/
                    background: transparent;
                }
                &:hover {
                    color: #FFF;
                    &:after {
                        width: 100%;
                        background: #FFF;
                    }
                }
            }
            > ul  {
                position: absolute;
                z-index: 999;
                display: block;
                background-color: $white;
                transform: scaleY(0);
                opacity: 0;
                top: 100%;
                left: 0;
                transition: opacity 300ms ease;
                padding: 23px 0 5px 0;
                width: 220px;

                 li {
                    list-style-type: none;
                    display: block;
                    padding: 0;
                    position: relative;
                     &.has-sub:after {
                         position:absolute;
                         right:10px;
                         top: 9px;
                         font-weight: 600;
                         color: $font-color;
                         content: '\f0da';
                         @extend .far;
                         @extend .fa-chevron-right;

                     }
                    a {
                        font-size: 1rem;
                        color: $font-color;
                        padding: 5px 7px;
                        text-decoration: none !important;
                        text-transform: none;
                        display: block;
                        &:hover {

                        }
                    }
                    > ul {
                        position: absolute;
                        z-index: 999;
                        display: block;
                        background-color: $white;
                        transform: scaleY(0);
                        opacity: 0;
                        top: -5px;
                        left: 100%;
                        transition: opacity 300ms ease;
                        width: 220px;
                        padding: 5px 0;
                    }

                    &:hover {
                        &:after {
                            color: $white;
                        }
                        > a {
                            /*background-color: $pastel-2-accent;*/
                            background-color: $main-color;
                            color: $white
                        }
                        & > ul {
                            opacity: 1;
                            transform: scaleY(1);

                        }
                    }
                }
            }
            &:hover {
                &> ul {
                    opacity: 1;
                    transform: scaleY(1);
                }
                //span {
                //    &:after{
                //        content: "";
                //        position: absolute;
                //        width: 0;
                //        height: 0;
                //        border-left: 7px solid transparent;
                //        border-right: 7px solid transparent;
                //        border-bottom: 7px solid $black;
                //        bottom: 1px;
                //        left: 50%;
                //        margin-left: -10px;
                //
                //    }
                //}


            }
        }


        .category-item {
            position: static;

            & > .sub-menu {
                @extend .row;
                //@extend .justify-content-center;
                @extend .px-lg-5;
                padding-left: 0;
                width: 100vw;
                left: 15px;
                background-color: $white;
                list-style-type: none;
                border-bottom: 1px solid #ccc;

                & > li {
                    @extend .col-12;
                    @extend .col-lg-4;
                    @extend .col-xl;
                    @extend .pb-lg-4;
                    @extend .px-4;

                    & > a, & > span {
                        display: block;
                        margin-bottom: 20px;
                        font: {
                            family: $h-font;
                            style: italic;
                            size: 1.30rem;
                            weight: bold;
                        }
                        text-transform: none;
                        color: $main-color;
                    }

                    & > .sub-menu {
                        list-style-type: none;
                        padding-left: 0;

                        & > li {
                            margin-bottom: 10px;
                            line-height: 18px;

                            & > a {

                                text-transform: uppercase;
                                font: {
                                    family: $main-font;
                                    style: normal;
                                    size: 16px;
                                }
                                line-height: 18px;
                                color: $font-color;
                            }

                            & > .sub-menu {
                                list-style-type: none;
                                padding-left: 0;
                                opacity: .7;

                                & > li, & > li > a {
                                    color: $font-color;
                                    text-transform: none;
                                    line-height: 24px;
                                    font: {
                                        weight: normal;
                                        size: 16px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

}

.menu-main-menu > li.active, .menu-main-menu > li.active > * {
    /*color: $pastel-3-accent !important;*/
    color: #FFF !important;
}


.menu-mobile-menu > li.active, .menu-mobile-menu > li.active > * {
    /*color: $pastel-3-accent !important;*/
    /*color: #CC0000 !important;*/
    color: #FFF !important;
}




.menu-main-menu > li.active a::after,
 .menu-mobile-menu > li.active a::after {
    background: #FFF;
}



.menu-mobile-menu > li > * {
    position: relative;
}

.menu-mobile-menu > li > *:first-child::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background-image: linear-gradient(to right, transparent 50%, $pastel-2 50%);
    transition: all 300ms ease;
    background-size: 22px 100%;
}

.menu-main-menu > li.active > *::after,
.menu-mobile-menu > li.active > *::after {
    width: 100%;
}


.mobile-menu {
    padding: 40px 0;
    width: 100vw;
    overflow-x: hidden ;
    z-index: -1;
    text-align: center;
    ul {
        list-style-type: none;
        padding-left: 0;
        li {
            a {
                display: block;
                @extend .py-2;
                font-size: 1.2rem;
                color: $main-color;
                text-transform: uppercase;
            }
        }
        span {
            display: none !important;
        }
    }
    .category-item {
        position: relative;
        > .sub-menu {
            background-color: $black;
            border-bottom: none;
            margin: 0;
            > li {
                & > a, & > span {
                    font-family: $main-font;
                    font-size: 1.1rem;
                    color: $light-font-color;
                    margin-bottom: 0;
                    text-transform: uppercase;
                    font-style: normal;
                    width: 100%;
                }
                & > span {
                    display: none !important;
                    padding-bottom: 0 !important;
                }
                > ul {
                    margin: 0;
                     li  {
                        & > a, & > span {
                            font-size: 1.2rem;
                            color: $light-font-color !important;
                            margin-bottom: 0;
                            text-transform: none !important;
                        }

                        ul {
                           // display:none;
                        }
                    }

                }
            }
        }
    }
}
#topbar {
    background-color: $black;
    color: $white;
    ul {
        list-style-type: none;

        li {
            a {
                display: inline-block;
                float: right;
                @extend .py-2;
                @extend .pl-3;
                font-size: 1rem;
                color: $light-font-color;
                text-decoration: none !important;
                transition: all 300ms ease;
                &:hover {
                    color: $main-color;
                }
            }
        }
        span {
            display: none !important;
        }
    }
}

.mobile-menu-icon {
    width: 30px;
    display: inline-block;
    padding-top: 30px;
    input[type=checkbox] + label {
        position: relative;
        height: 30px;
        width: 30px;
        z-index: 5;
        &:before {
            display: none;
        }
        span {
            position: absolute;
            width: 30px;
            height: 2px;
            top: 50%;
            margin-top: -1px;
            left: 0;
            display: block;
            /*background: $black;*/
            background: #FFF;
            transition: .5s;
            transform-origin: center;
        }
        span:first-child {
            top: 3px;
        }
        span:last-child {
            top: 9px;
        }
    }
    label:hover {
        cursor: pointer;
    }
    input:checked + label {
        span {
            opacity: 0;
            top: 9px !important;
        }
        span:first-child {
            opacity: 1;
            transform: rotate(405deg);
        }
        span:last-child {
            opacity: 1;
            transform: rotate(-405deg);
        }
    }

}
@include media-breakpoint-down(md) {

.menu-container {
    opacity: 0;
    overflow: hidden;
    max-height: 0;
    transition: all 400ms ease-in-out;
    &.open {
        max-height: 100vh;

        opacity: 1;
    }

}
}
