@import 'vendor/bootstrap/bootstrap';
@import '~uikit/src/scss/variables';
@import '~uikit/src/scss/uikit';
@import 'vendor/fontawesome/fontawesome';
@import 'vendor/slick-slider/slick',
'vendor/slick-slider/slick-theme';

@import "variables/sizes",
"variables/colors",
"variables/fonts";

@import "global/global",
"global/form/checkbox",
"global/form/slider";

@import "layout/header",
"layout/nav",
"layout/footer";

@import "pages/home",
"pages/product",
"pages/design",
"pages/about",
"pages/contact",
"pages/legal",
"pages/contact-confirmation",
"pages/order-confirmation",
"pages/send-design",
"pages/login",
"pages/cart",
"pages/checkout",
"products/categories",
"products/product-list",
"products/detail",
"products/related";

@import 'checkout/cart';

@import "sections/home-header",
"sections/usps",
"sections/bottom-cta";


button:focus {
    outline: none !important;
}

$font1: roboto-condensed, sans-serif;
$font2: lato, sans-serif;

[class*=uk-animation-] {
    animation-duration: 0.5s !important;
}


.social-buttons svg {
    max-width: 24px;
    max-height: 24px;
}

.fixed-tel-icon {
    position: fixed;
    top: 175px;
    right: 0;
    border-radius: 4px 0px 0px 4px;
    background: #CC0000;
    width: 40px;
    height: 40px;
    z-index: 99;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.fixed-mail-icon {
    position: fixed;
    top: 225px;
    right: 0;
    border-radius: 4px 0px 0px 4px;
    background: #CC0000;
    width: 40px;
    height: 40px;
    z-index: 99;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.fixed-tel-icon:hover,
.fixed-tel-icon:focus,
.fixed-mail-icon:hover,
.fixed-mail-icon:focus {
    background: #121212;
}

.fade.in {
    opacity: 1;
}

.logincontainer {
    padding-bottom: 148px !important;
}

.user-details > .container,
.myorderssection > .container,
.account-order-detail-section > .container {
    max-width: 1230px;
}

.get-order-detail, .back-btn {
    color: #121212;
    text-underline-position: under;
}
.get-order-detail:hover,
.get-order-detail:focus,
.back-btn:hover,
.back-btn:focus {
    color: #CC0000;
}




@media (max-width: 959px) {
    .user-details, .myorderssection, .account-order-detail, .cart-content-section {
        padding-bottom: 135px !important;
    }
}


@media (max-width: 939px) {
    .category-bottom-usps ul, .product-bottom-usps ul {
        margin-left: auto;
        /*margin-right: -55px;*/
        margin-right: 0px;
    }

    .category-bottom-usps ul li, .product-bottom-usps ul li {
        flex: 0 1 auto;
    }

    .category-bottom-usps ul li:not(:last-child), .product-bottom-usps ul li:not(:last-child) {
        padding-bottom: 30px;
    }


    /*.category-bottom-usps ul li, .product-bottom-usps ul li {
        padding-left: inherit;
        padding-right: inherit;
    }

    .category-bottom-usps ul li:nth-child(1), .product-bottom-usps ul li:nth-child(1) {
        padding-left: inherit;
        padding-right: inherit;
    }

    .category-bottom-usps ul li:nth-child(2),
    .category-bottom-usps ul li:nth-child(3),
    .product-bottom-usps ul li:nth-child(2),
    .product-bottom-usps ul li:nth-child(3) {
        padding-left: inherit;
        padding-right: inherit;
    }*/

}


@media (min-width: 427px) {
     .header-logo-mobile {
         display: none;
     }
}

@media (max-width: 426px) {
    .header-logo {
        display: none;
    }

    .mobile-menu-block-logo .header-logo {
        display: inline;
    }

    .mobile-menu-block-logo .header-logo-mobile {
        display: none;
    }

    header .col-8, header .col-4 {
        flex: 0 0 50%;
        max-width: 50%;
    }

}
