.site-footer {

    .footer-logo-mobile-div {
        display: flex;
        justify-content: center;
        margin-top: 40px;
    }

    .footer-col2 svg, .footer-col2 .subcategories-list {
        display: none;
    }

    padding: {
        top: 80px;
        bottom: 80px;
    }

    @media(min-width: 992px){

        .footer-col1 {
            flex: 0 0 25%;
            max-width: 25%;
        }

        .footer-col2 {
            flex: 0 0 25%;
            max-width: 25%;
        }

        .footer-col3 {
            flex: 0 0 28%;
            max-width: 28%;
            padding-left: 3%;
        }

        .footer-col4 {
            text-align: right;
            flex: 0 0 19%;
            max-width: 19%;
        }
    }


    @media(min-width: 1200px){

        .footer-col1 {
            flex: 0 0 30%;
            max-width: 30%;
        }

        .footer-col2 {
            flex: 0 0 20%;
            max-width: 20%;
        }

        .footer-col3 {
            flex: 0 0 28%;
            max-width: 28%;
            padding-left: 3%;
        }

        .footer-col4 {
            text-align: right;
            flex: 0 0 19%;
            max-width: 19%;
        }
    }


    @media(min-width: 1440px){
        > .container {
            /*max-width: 1180px;*/
            max-width: 1270px;
        }
    }

    @media (max-width: 1199px) {
        > .container {
            max-width: none;
        }
    }


    @media (max-width: 991px) {
        padding: {
            top: 40px;
            bottom: 40px;
        }

        .footer-col1, .footer-col2 {
            padding-bottom: 20px;
        }
        .footer-col3 {
            padding-bottom: 40px;
        }
        ul li + li {
            margin-top: 5px !important;
        }
        ul > div > div > div {
            margin-bottom: 5px !important;
        }

    }

    @media (max-width: 959px) {
        .footer-col3 .footer-logo-mobile {
            display: none;
        }
    }

    @media (min-width: 768px) {
        .footer-col1 > div {
            text-align: left;
        }
    }

    @media (max-width: 767px) {

        * {
            text-align: center;
        }

        .footer-col1 {
            margin-bottom: 0;
        }
    }

    img.footer-logo {
        margin-bottom: 20px;
    }

    * {
        font: {
            family: $font2;
            weight: 400;
            size: 18px;
        }
        line-height: 28px;
        color: $black;
    }

    strong {
        font: {
            family: $font4;
            weight: 700;
        }
    }

    strong + address {
        margin-top:0;
    }

    a:hover,
    a:focus {
        color: $orange;
        text-underline-position: under;
    }

    h3 {
        font: {
            family: $main-font;
            weight: 700;
            size: 14px;
        }
        line-height: 20px;
        color: #000;
    }

    ul {
        padding-left: 0;
        list-style: none;
    }

    ul li + li {
        margin-top: 20px;
    }

    ul > div > div {
        padding-top: 0 !important;
    }

    ul > div > div > div {
        margin-bottom: 20px;
    }

    .footer-col3 a:last-of-type {
        text-decoration: underline;
        text-underline-position: under;
    }

}
