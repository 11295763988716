
.bottom-cta {
    position: relative;
    padding-top: 155px;
    padding-bottom: 20px;
}

.bottom-cta::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 105px;
    background: url('/images/ruler.jpg');
}

.bottom-cta p {
    font-family: $font2;
    font-size: 30px;
    line-height: normal;
    max-width: none;
}

.bottom-cta-bold {
    font-family: $font4;
}

.regard-offer a {
    display: inline-block;
    width: 186px;
    padding: 9px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: #FFF;
    color: #CC0000;
    text-align: center;
    font-family: $font1;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    transition: all 300ms ease-in-out;
}

.regard-full-offer a {
    display: inline-block;
    width: auto;
    padding: 9px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: #FFF;
    color: #CC0000;
    text-align: center;
    font-family: $font1;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    transition: all 300ms ease-in-out;
}

.regard-offer a:hover,
.regard-offer a:focus,
.regard-full-offer a:hover,
.regard-full-offer a:focus {
    text-decoration: none;
    background: #121212;
    color: #FFF;
}

.bottom-cta p.homectap1 {
    max-width: 892px;
    margin-left: auto;
    margin-right: auto;
}


@media (max-width: 767px) {
    body * {
        hyphens: auto;
    }

    .bottom-cta p {
        font-size: 24px;
    }

}
