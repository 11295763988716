
.over-ons-main {
    padding-top: 145px;
    padding-bottom: 100px;
}

.over-ons-main-row1,
.over-ons-main-row2 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.over-ons-main-row1 .col-md-5 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
}

.over-ons-main-row2 .col-md-5 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
}

.over-ons-main-row1 img,
.over-ons-main-row2 img {
    max-height: 288px;
    object-fit: contain;
}

.over-ons-main-row2 {
    margin-top: 40px;
}

#over-ons-1 {
    background-color: #f2c1a1;
    position: relative;
    height: 700px;
    color: #fff;
    z-index: 0;
    overflow: hidden;
}


#over-ons-1::before {
    position: absolute;
    z-index: -1;
    width: 57%;
    height: 100%;
    bottom: -90px;
    left: -120px;
    color: #cbc9da;
    content: "";
    background-image: url('/images/ontwerp-w.svg');
    background-size: 100%;
    background-position: 0% 0%;
    background-repeat: no-repeat;
    opacity: 1;
    max-width: 822px;
    max-height: 681px;
}

#over-ons-1 > .container {
    height: 100%;
}


#over-ons-1 h1 {
    color: #FFF;
}

#over-ons-1 p {
    font-family: $main-font;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
}

#over-ons-1 p,
#over-ons-2 p {
    max-width: 580px;
}



#over-ons-1 .filled-image {
    position: absolute;
    height: 700px;
    right: 0px;
    padding-left: 0;
    padding-right: 0;
    top: 0;
}


#over-ons-2 {
    background-color: #C5D8D6;
    position: relative;
    height: 600px;
    color: #464949;
    z-index: 0;
    overflow: hidden;
}


#over-ons-2::before {
    position: absolute;
    z-index: -1;
    width: 45%;
    height: 90%;
    bottom: -75px;
    right: -33px;
    color: #8BA9AD;
    content: "";
    background-image: url('/images/ontwerp-w2.svg');
    background-size: 100%;
    background-position: bottom right;
    background-repeat: no-repeat;
    opacity: 1;
}

#over-ons-2 .col-lg-7 {

    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;

}


#over-ons-2 > .container {
    height: 100%;
}

#over-ons-2 h2 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;
    color: #464949;
}

#over-ons-2 p, #over-ons-2 ul {
    max-width: 580px;
}

#over-ons-2 ul li, #over-ons-2 ul li strong {

    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;

}

#over-ons-2 ul li strong {
    font-weight: 700;
}

#over-ons-2 .filled-image {

    position: absolute;
    height: 600px;
    left: 0px;
    padding-left: 0;
    padding-right: 0;
    top: 0;

}

/*
#over-ons-2 a {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    text-decoration-line: underline;
    text-underline-position: under;
    color: #464949;
}*/















#over-ons-1 .filled-image > div, #over-ons-1 .filled-image > div *,
#over-ons-2 .filled-image > div, #over-ons-2 .filled-image > div * {
    height: 100%;
}

#over-ons-1 .filled-image img,
#over-ons-2 .filled-image img {
    object-fit: cover;
}




@media (max-width: 991px) {

    #over-ons-1 > .container,
    #over-ons-2 > .container {
        max-width: none;
    }

    #over-ons-1 p, #over-ons-2 p {
        max-width: none;
    }

    #over-ons-1 > div > div > div:nth-child(1) {
        order: 2;-webkit-order: 2;
    }

    #over-ons-1 > div > div > div:nth-child(2) {
        order: 1;-webkit-order: 1;
    }

    #over-ons-2 > div > div > div:nth-child(1) {
        padding-left: 0;
        padding-right: 0;
    }

    #over-ons-1 > div > div > div:nth-child(1),
    #over-ons-2 > div > div > div:nth-child(2) {
        padding-top: 40px;
        padding-bottom: 20px;
    }

    #over-ons-1, #over-ons-2 {
        height: auto;
    }

    #over-ons-1 .filled-image,
    #over-ons-2 .filled-image {
        position: relative;
        height: auto;
    }

}




@media (max-width: 959px) {

    #over-ons-1,
    #over-ons-2 {
        height: auto;
    }

    #over-ons-1 .filled-image,
    #over-ons-2 .filled-image {
        position: relative;
        height: auto;

    }

    #over-ons-1 .col-lg-7,
    #over-ons-2 .col-lg-7 {
        padding-top: 40px;
        padding-bottom: 40px;
    }

  /*  #over-ons-2 .col-lg-5 {
        order: 2;-webkit-order: 2;
    }

    #over-ons-2 .col-lg-7 {
        order: 1;-webkit-order: 1;
    }*/

}



@media (max-width: 959px) {
    .over-ons-main {
        padding-bottom: 20px;
    }
}


@media (max-width: 767px) {

    .over-ons-main-row1 > .col-md-7 {
        padding-top: 40px;
    }

    .over-ons-main-row2 > .col-md-7 {
        order: 2;-webkit-order: 2;
        padding-top: 40px;
    }
    .over-ons-main-row2 > .col-md-5 {
        order: 1;-webkit-order: 1;
    }
}
