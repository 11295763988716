.header-desktop-home {
    background-color: transparent;
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    animation-duration: 0s !important;
    transition: 0s !important;
}

.header-desktop-home.uk-sticky-fixed {
    position: fixed !important;
    background: #CC0000;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04), 0px 15px 33px 0px rgba(0, 0, 0, 0.04), 0px 61px 61px 0px rgba(0, 0, 0, 0.03), 0px 137px 82px 0px rgba(0, 0, 0, 0.02), 0px 243px 97px 0px rgba(0, 0, 0, 0.01), 0px 380px 106px 0px rgba(0, 0, 0, 0.00);
    animation-duration: 0s !important;
    transition: 0s !important;
    /*height: 108px !important;*/
}

.header-desktop-home.uk-sticky-fixed + .uk-sticky-placeholder {
    /*height: 108px !important;*/
    animation-duration: 0s !important;
    transition: 0s !important;
    position: fixed !important;
}

.header-desktop-home.uk-sticky-fixed .top-menu-icons a:hover img,
.header-desktop-home.uk-sticky-fixed .top-menu-icons a:focus img {
    filter: brightness(1) invert(1);
}

/*
header.uk-animation-slide-top,
header.uk-animation-enter,
header.uk-animation-leave,
header.uk-animation-reverse,
header[class*=uk-animation-] {
    -webkit-transform: translate3d(0, 0, 0) !important;
    -moz-transform: translate3d(0, 0, 0) !important;
    -ms-transform: translate3d(0, 0, 0) !important;
    -o-transform: translate3d(0, 0, 0) !important;
    transform: translate3d(0, 0, 0) !important;
    animation-duration: 0s !important;
    transition: 0s !important;
    position: fixed !important;
    top: 0 !important;
    background: #CC0000;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04), 0px 15px 33px 0px rgba(0, 0, 0, 0.04), 0px 61px 61px 0px rgba(0, 0, 0, 0.03), 0px 137px 82px 0px rgba(0, 0, 0, 0.02), 0px 243px 97px 0px rgba(0, 0, 0, 0.01), 0px 380px 106px 0px rgba(0, 0, 0, 0.00);
    z-index: 9999;
}

.uk-sticky[class*=uk-animation-],
.uk-animation-reverse {
    -webkit-transform: translate3d(0, 0, 0) !important;
    -moz-transform: translate3d(0, 0, 0) !important;
    -ms-transform: translate3d(0, 0, 0) !important;
    -o-transform: translate3d(0, 0, 0) !important;
    transform: translate3d(0, 0, 0) !important;
    animation-duration: 0s !important;
    transition: 0s !important;
    position: fixed !important;
    top: 0 !important;
    background: #CC0000;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04), 0px 15px 33px 0px rgba(0, 0, 0, 0.04), 0px 61px 61px 0px rgba(0, 0, 0, 0.03), 0px 137px 82px 0px rgba(0, 0, 0, 0.02), 0px 243px 97px 0px rgba(0, 0, 0, 0.01), 0px 380px 106px 0px rgba(0, 0, 0, 0.00);
    z-index: 9999;
}

 */

/*header.uk-sticky,*/
/*header.uk-sticky-below,
header.uk-sticky-fixed {
    -webkit-transform: translate3d(0, 0, 0) !important;
    -moz-transform: translate3d(0, 0, 0) !important;
    -ms-transform: translate3d(0, 0, 0) !important;
    -o-transform: translate3d(0, 0, 0) !important;
    transform: translate3d(0, 0, 0) !important;
    animation-duration: 0s !important;
    transition: 0s !important;
    position: fixed !important;
    top: 0 !important;
    background: #CC0000;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04), 0px 15px 33px 0px rgba(0, 0, 0, 0.04), 0px 61px 61px 0px rgba(0, 0, 0, 0.03), 0px 137px 82px 0px rgba(0, 0, 0, 0.02), 0px 243px 97px 0px rgba(0, 0, 0, 0.01), 0px 380px 106px 0px rgba(0, 0, 0, 0.00);
    z-index: 9999;
}
*/

.home-header {
    background: url('/images/preparation-wooden-planks-by-carpenter-1.jpg') no-repeat 50% 50%;
    background-size: cover;
    position: relative;
    padding-top: 170px;
    padding-bottom: 140px;
}

.home-header::before {
    content: '';
    display: inline-block;
    background: url('/images/Ellipse-1.png') no-repeat 50% 50%;
    width: 100%;
    height: 100%;
    max-width: 472px;
    max-height: 472px;
    position: absolute;
    top: -130px;
    left: -70px;
    border-radius: 50%;
}


.home-header h1 {
    font-family: $font2;
    font-size: 48px;
    text-transform: none;
}

.home-header h1 span {
    font-family: $font4;
}

.home-header > div {
    max-width: 618px;
    margin-left: auto;
    margin-right: auto;
}

.home-header + .usps {
    position: relative;
}

.home-header + .usps > .container {
    max-width: 1036px;
    background-color: #FFF;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding-top: 48px;
    padding-bottom: 48px;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04), 0px 15px 34px 0px rgba(0, 0, 0, 0.04), 0px 61px 61px 0px rgba(0, 0, 0, 0.03), 0px 138px 83px 0px rgba(0, 0, 0, 0.02), 0px 246px 98px 0px rgba(0, 0, 0, 0.01), 0px 384px 108px 0px rgba(0, 0, 0, 0.00);
}

.home-header + .usps ul {
    margin-bottom: 0;
}

.home-header + .usps ul li {
    color: #121212;
    font-family: $font2;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

@media (min-width: 1440px) {
    .page-home .header-logo {
        width: 361px;
    }

    .home-header h1 {
        padding-left: 40px;
        padding-right: 40px;
    }
}



@media (max-width: 959px) {
    .home-header h1, body .uk-h1, body h1 {
        font-size: 40px;
        line-height: 48px;
    }

    body .uk-h2, body h2 {
        font-size: 22px;
        line-height: 28px;
    }
}


@media (max-width: 767px) {
    .home-header h1, body .uk-h1, body h1 {
        font-size: 36px;
        line-height: 44px;
        text-transform: none;
    }

    .home-header {
        padding-left: 25px;
        padding-right: 25px;
    }
}

