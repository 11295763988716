.account-order-detail .cart-totals .row {
    margin-left: 0;
    margin-right: 0;
}

@media (max-width: 1199px) {
    .logincontainer {
        max-width: none;
    }
}
