
.legal-main {
    padding-top: 165px;
}

@media (min-width: 1200px) {
    .legal-main > .container {
        max-width: 1230px;
    }
}

@media (min-width: 960px) {
    .legal-main {
        /*padding-top: 70px;*/
        padding-bottom: 70px;
    }
    .privacyverklaring-main-content-table-mobile {
        display: none;
    }

    .privacyverklaring-main-content-table-desktop {
        overflow-x: auto;
    }
    .privacyverklaring-main-content-table-desktop table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.5);
    }

    .privacyverklaring-main-content-table-desktop table th,
    .privacyverklaring-main-content-table-desktop table td {
        padding: 5px 15px 5px 5px;
        border: 1px solid rgba(0, 0, 0, 0.5);
    }
}


@media (max-width: 1199px) {
    .legal-main > .container {
        max-width: none;
    }
}


@media (max-width: 959px) {
    .legal-main {
        padding-top: 165px;
        padding-bottom: 20px;
    }
    .privacyverklaring-main-content-table-desktop {
        display: none;
    }
}

@media (max-width: 767px) {
    body .legal-main .uk-h1, body .legal-main h1 {
        font-size: 28px;
        line-height: 36px;
    }
}
