body {
    font-family: $font2;
    color: $black;
    overflow-x: hidden;
    font-weight: 300;
}



.white-txt {
    color: $white;
}

.darkgrey-bg {
    background-color: $darkgrey;
}

.orange-bg {
    background-color: $orange;
}

.txt-center {
    text-align: center;
}

.uk-button.uk-button-default {
    width: 271px;
    padding-top:10px;
    padding-bottom: 10px;
    border-radius: 7px;
    background-color: $white;
    transition: all 300ms ease-in-out;
    font-size: 18px;
    &:hover {
        background-color: $black;
        color: $white;
    }
}
.uk-button.btn-dark {
    background-color: $main-color;
    color: $white;
}

textarea {
    background-color: transparent !important;
    background: transparent !important;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="file"]) {
    /*border: 1px solid $pastel-3-accent;*/
    /*border: 1px solid $brown-v1;*/
    border: 1px solid #C4C4C4;
    background-color: transparent;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 40px;
}

input[type="file"] {
    background-color: transparent;
    border: none;
    padding-left: 0;
    padding-right: 0;
}

.cart-qty-selector input:not([type="checkbox"]):not([type="radio"]) {
    height: 45px;
}


input:not([type="checkbox"]):not([type="radio"]), textarea {
    font-family: $font2;
    font-weight: 300;
    font-size: 18px !important;
    line-height: 28px !important;
}

.uk-select:not([multiple]):not([size]) {
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 20px;
    background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='16' height='16' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='black'/></g></svg>");
    background-repeat: no-repeat;
    background-position: 94% 50%;
    /*border: 1px solid $pastel-3-accent;*/
    border: 1px solid $pastel-3;
    background-color: transparent;
    padding-top: 5px;
    padding-bottom: 5px;
}

.select2-container {
    width: 100% !important;
    .selection {
        height: 38px;
        .select2-selection {
            height: 38px;
            @extend .form-control;
            /*border: 1px solid $pastel-3-accent;*/
            border: 1px solid $pastel-3;
            border-radius: 0;
            background-color: transparent !important;
            padding-top: 5px;
            padding-bottom: 5px;
        }
         .select2-selection__arrow {
             height: 38px !important;
         }
    }
}

.price-input  {
    position: relative;
    &:before {
        position: absolute;
        content: "€";
        top: 8px;
        left: 22px;
        /*color: $pastel-3-accent;*/
        color: $pastel-3;
    }
    input {
        text-align: right;
    }
}
.break {
    flex-basis: 100%;
    height: 0;
}

.qty-selector {
    position: relative;
    .qty-select-open {
        height: 45px;
        width: 45px;
        background-color: #CC0000;
        border: 1px solid #CC0000;
        /*background-color: $brown-v1;
        border: 1px solid $brown-v1;*/
        color: $white;
        text-align: center;
        margin-left: -4px;
        padding: 10px 0;
        cursor: pointer;
    }
    .qty-field {
        border: 1px solid #121212;
        /*border: 1px solid $brown-v1;*/
        height: 45px; width: 80px;
        text-align: center;
        vertical-align: top;

    }
    .qty-select-options {
        position: absolute;
        left: 0;
        z-index: 99;
        top: 100%;
        background-color: $white;
        border: 1px solid rgba(204, 204, 204, 1);
        /*border: 1px solid $brown-v1;*/
        border-top: none;
        width: 125px;
        height: 200px;
        overflow-y: auto;
        transform: scaleY(0);
        transform-origin: top;
        opacity: 0;
        transition: all 300ms ease-in-out;

        &.open {
            transform: scaleY(1);
            opacity: 1;

        }
        /* width */
        &::-webkit-scrollbar {
            width: 10px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background:rgba(204, 204, 204, 0.2);
            /*background: $brown-v1;*/
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: rgba(204, 204, 204, 0.2);
            /*background: $brown-v1;*/
        }
        scrollbar-width: thin;
        scrollbar-color: rgba(18, 18, 18, 0.6) #f1f1f1;
        /*scrollbar-color: $brown-v1 #f1f1f1;*/
        div {
            &:hover {
                background-color: rgba(204, 204, 204, 0.2);
                /*background-color: rgba($brown-v1, .6);*/
            }
        }
    }
}

.page-btn {
    background: $light-bg-color;
    border-radius: 100%;
    border: 1px solid $brown;
    margin-right: 10px;
    padding: 0;
    height: 39px;
    width: 39px;
    text-align: center;

    span, a {
        color: $brown !important;
    }
}

.page-btn.active {
    background: $brown;

    span, a {
        color: $pastel-1 !important;
    }
}

.pager {
    /*border-top: 1px solid $brown;*/
    padding-top: 20px;

    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;

    .page-btn {
        background: transparent;
        border: none;
        color: $black !important;
        width: auto;
        height: auto;
    }

    .justify-content-center .page-btn {
        margin-left: 5px;
        margin-right: 5px;
    }

    .page-btn a, .page-btn span {
        color: $black !important;
    }

    .page-btn.active span {
        /*color: $pastel-2 !important;*/
        color: #CC0000 !important;
        font-weight: 900;
    }

    .prev-btn {
        margin-right: auto;

        a {
            background: transparent !important;
            color: $black !important;
        }

        svg {
            width: 30px;
            height: 30px;
        }

        svg, svg path {
            /*color: $pastel-2;*/
            color: #CC0000;
        }

    }

    .next-btn {
        margin-left: auto;

        a {
            background: transparent !important;
            color: $black !important;
        }

        svg {
            width: 30px;
            height: 30px;
        }

        svg, svg path {
            /*color: $pastel-2;*/
            color: #CC0000;
        }

    }
}


.pager .page-btn a:hover, .pager .page-btn a:focus {
    color: #CC0000 !important;
}

.pager .prev-btn svg:hover, .pager .prev-btn svg:hover path,
.pager .next-btn svg:hover, .pager .next-btn svg:hover path,
.pager .prev-btn svg:focus, .pager .prev-btn svg:focus path,
.pager .next-btn svg:focus, .pager .next-btn svg:focus path {
    color: #121212;
}


a.txt-link {
    color: #CC0000;
    text-decoration: underline;
    text-underline-position: under;
}

a.txt-link:hover, a.txt-link:focus {
    color: #121212;
}

@media (min-width: 768px) {
    .pager {
        padding-top: 55px;
    }
}


@media (max-width: 767px) {
    .filter-mob + #mobile-filter {
        padding-top: 20px;
    }
}
