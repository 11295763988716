.cart-header {
    background: $pastel-1;
    position: relative;
    z-index: 0;
}

.cart-header::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 50%;
    height: calc(100%);
    transform: translateX(0%);
    opacity: 0.2;
    background-image: url('/images/w.svg');
    background-repeat: no-repeat;
    background-size: contain;
}

.cart-header h1 {
    position: relative;
    z-index: 0;
    display: inline-block;
}


.cart-header-section h1 {
    font-family: $font2;
    font-weight: 300;
}

.cart-header-section h1 span {
    font-family: $font4;
    font-weight: 700;
}

.cart-header h1::before {
    content: "";
    position: absolute;
    bottom: -10px;
    left: -10px;
    width: calc(100% + 10px);
    height: 3px;
    background-image: linear-gradient(to right, transparent 50%, #f2c1a1 50%);
    background-size: 22px 100%;
}



.cart-content-section .cart-totals > .row {
    margin-left: 0;
    margin-right: 0;
}



.cart-item-block strong {
    font-family: $font4;
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    color: #121212;
    display: block;
    margin-bottom: 20px;
}


.cart-content-row + .cart-content-row {
    border-top: none !important;
    /*margin-top: 40px;*/
}

.cart-content-row {
    margin-bottom: 40px;
}

.cart-content-row > .cart-content-row {
    margin-bottom: 0;
}

.cart-item-block {
    /*background: rgba($pastel-3-accent, 0.2);*/
    background: rgba(204,204,204,0.2);
}

.cart-item-block .col-md-4 .image-container {
    height: 100%;
}


.cart-item-block .col-md-4 .image-container img {
    height: 100%;
    object-fit: cover;
}

.cart-item-block .col-md-8 {
    padding: 20px 30px;
}

.cart-item-block .cart-qty-selector {
    margin-top: 5px;
    margin-bottom: 5px;
}

.cart-content-header {
    border-bottom: none !important;
    margin-top: 10px;
}

.cart-content-header > div {
    padding-left: 0;
    padding-right: 0;
}

.cart-totals .total-amount {
    border: none !important;
    /*background-color: rgba($pastel-3-accent, 0.2) !important;*/
    background-color: rgba(204,204,204,0.2) !important;
    padding-top: 10px;
    padding-bottom: 10px;
}

.cart-item-block {
    border-radius: 4px;
}

.cart-content-main {
    border-radius: 4px;
    overflow: hidden;
}

.cart-totals .total-amount + .total-amount {
    border-top: 1px solid rgba(#121212, 0.8) !important;
}

.order-btn, .proceed-button a {
    border-radius: 4px;
}




.cart-header-section {
    position: relative;
}


.cart-header-section > .container {
    position: relative;
    z-index: 0;
    padding-top: 40px;
    padding-bottom: 20px;
    position: relative;
    max-width: 1230px;
}


.cart-header-section > .container::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 15px;
    right: 15px;
    height: 1px;
    width: calc(100% - 30px);
    background: #CCC;
}


.cartnavsteps {
    margin-top: 40px;
}

.cartnavsteps .form-steps  {
    padding-left: 0px;
    margin-bottom: 0px;
}


.cartnavsteps .form-steps li {
    border: none;
    transform: none;
    margin-right: 0px;
}


.cartnavsteps .form-steps li.enabled,
.cartnavsteps .form-steps li.current {
    background-color: transparent;
}

.cartnavsteps .form-steps li a, .cartnavsteps .form-steps li span {
    transform: none;
    padding: 0;
}


.cartnavsteps .form-steps li a, .cartnavsteps  .form-steps li span {
    font-family: $font1;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #121212;
}


.cartnavsteps  .form-steps li.enabled a, .cartnavsteps  .form-steps li.enabled span, .cartnavsteps  .form-steps li.current a, .cartnavsteps  .form-steps li.current span {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #CC0000;
}


.cart-section > div {
    max-width: 1230px;
}

@media (min-width: 768px) {
    .cart-content-section {
        padding-top: 35px;
        padding-bottom: 165px;
    }
}


@media (min-width: 992px) {
    .cart-header .col-lg-7 {
        padding-left: 140px;
    }

    .checkout-page-form .cart-totals-html-mobile {
        display: none;
    }

}


@media (max-width: 1199px) {
    .cartnav, .cart-content-section {
        max-width: none;
    }

    .cart-content-section .cart-page-cart-content-totals {
        margin-left: 0;
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }
}


@media (max-width: 991px) {
    .cart-header .col-lg-7 {
        padding: 40px 30px;
    }

    .cart-content-section .cart-page-cart-content-totals {
        margin-top: 40px;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .cart-content-section .cart-page-cart-content-totals > div {
        padding-left: 0px !important;
    }

    .checkout-page-form .cart-totals-html-desktop {
        display: none;
    }

}


@media (max-width: 767px) {
    .cart-content-section {
        padding-top: 35px;
        padding-bottom: 65px;
    }
}
