.product-title {
    font: {
        family: $main-font;
        style: normal;
        weight: 400;
    }
    font-size: 42px;
    font-weight: 400;
    text-transform: none;
    @include media-breakpoint-down(sm) {
        font-size: 1.2rem;
    }
}
.banner {
    background: {
        image: url("/images/related-banner.png");
        size:cover;
    }
}

.product-detail-price {
    color: $main-color;
    line-height: 33.75px;
    font: {
        size: 24px;
        weight: 700;
    }
}

.lightgallerydiv {
    height: 573px;
}

.main-image {
    /*height: 477px;*/
    height: 543px;
    width: 100%;
    text-align: center;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &:hover {
        cursor: zoom-in;
    }
}

.image-thumb-container {
    cursor:pointer;
    /*border: 2px solid rgba($main-color, .3) !important;*/
    transition: all 400ms ease;
    &:hover {
        /*border: 2px solid $main-color !important;*/
    }
    img {
        border: none !important;
    }
}
*:focus {
    outline: none;
}

#product-detail {

    .slick-slide {
        /*margin: 10px 0;*/
        margin: 0px 0px 0px 0px;
    }
    .image-thumb {
        pointer-events: all !important;
    }
    .next, .prev {
        font-size: 24px;
        cursor:pointer;
        color: rgba($main-color, .8);
        transition: all 300ms ease;
        &:hover {
            color: $main-color;
        }
    }

}

i.toggle-wishlist {

    display: block;
    font-size: 2rem;
    margin-top: 15px;
    color: $main-color;

}
.ui.dropdown .menu {
    z-index: 99999;
}

.qty-group {
    position: relative;
    label {
        position: absolute;
        top: 4px;
        left: 6px;
        font-size: 10px;
        text-transform: uppercase;
    }
    .qty-up {
        position: absolute;
        bottom: 4px;
        right: -3px;
    }
    .qty-down {
        position: absolute;
        bottom: 4px;
        left: 9px;

    }
    .qty-field {
        text-align: center;
        padding-top: 29px;
        padding-bottom: 18px;
        border-radius: 6px;
        outline: none !important;
    }

}




.variant-select {
    border: 1px solid #ced4da;
    border-radius: 6px;
    padding-top: 18px;
    padding-bottom: 4px !important;
    padding-left: 6px;
    label {
        position: absolute;
        top: 4px;
        left: 6px;
        font-size: 10px;
        text-transform: uppercase;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.variants {
    .variant {


            @extend .px-2;

        .image-container {
            border: 2px solid $white;

        }
        &:hover, &.active-variant {
            .image-container {
                border: 2px solid $pastel-2-accent;
            }
        }
    }
}

.order-btn {
    /*background-color: $main-color;*/
    background-color: #CC0000;
    color: $white;
    padding: 10px 40px;
    /*border-radius: 5px;*/
    border-radius: 0px;
    display: inline-block;
    font-size: 18px;
    text-decoration: none !important;
    transition: all 300ms ease-in-out;
    &:hover {
        background-color: $black;
        color: $white;
    }
}

section#related-products {
    @extend .py-5;
    background-color: rgba(244, 237, 229, 0.5);
    .uk-position-small[class*=uk-position-center-left]{
        background-color: $pastel-2;
        color: $white;
        padding: 11px 20px ;
        transform: translateX(-120%) translateY(30px);
        font-size: 25px;
    }
    .uk-position-small[class*=uk-position-center-right] {
        background-color: $pastel-2;
        color: $white;
        padding: 11px 20px ;
        transform: translateX(120%) translateY(30px);
        font-size: 25px;
    }
    .new-content > div:first-of-type {
        text-align: center;
        @extend .mb-5;
    }
    h2 {
        display:inline-block;
        margin: 0 auto;
        text-align: center;
        text-transform: uppercase;
        position: relative;
        //border-bottom: 3px dashed $pastel-2;
    }
    li {
        padding-top: 40px;

        .new-product-item {
            position: relative;
            display: block;
            &:before {
                content: "";
                position: absolute;
                top: -30px;
                left: calc(50% - 50px);
                width: 50%;
                padding-bottom: 24%;
                background-image: url(/images/tape.svg?052e661…);
                background-size: 100%;
                background-position: bottom center;
                z-index: 999;
                background-repeat: no-repeat;
                transform-origin: center;
                opacity: .8;


            }

        }
        &:nth-child(2n) .new-product-item:before {
            //left: 50px;
            transform: scaleX(-1);
        }
        &:nth-child(3n) .new-product-item:before {
            //left: 50px;
            transform:  rotate(20deg);
        }
        &:nth-child(4n) .new-product-item:before {
            //left: 50px;
            transform: rotate(-20deg) scaleX(-1);
        }
    }
}


section#secondary-designs {
    background-color: $pastel-3;
    background-image: url(/images/ideas.svg);
    background-repeat: no-repeat;
    background-size: 300px;
    background-position: 0 -45px ;
    height: 240px;
    @extend .py-4;
    @extend .d-flex;
    @extend .align-items-center;
    h3 {
        font-size: 42px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: normal;
    }
    h4 {
        margin-top: -15px;
        font-size: 32px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
    }
.uk-button {
    box-shadow: 0 0 15px $pastel-3-accent;

}

}


#product-detail > form > .container, .detail-tabs-container {
    max-width: 1230px;
}

input[type=radio]:checked + label:before,
input[type=checkbox]:checked + label:before {
    color: #CC0000;
}

.prod-header-content input[type=radio]:checked + label:before {
    color: #CC0000;
}


.prod-header-content .category-detail-header h1::first-word {
    font-weight: bold;
    font-family: $font4;
}

.detail-tabs {
    padding-top: 70px;
}

.detail-tabs-container {
    /*padding-bottom: 100px;*/
}

.detail-tab-title ul {
    margin-left: -20px;
}

.detail-tab-title .nav-tabs {
    border-bottom: none;
}

.detail-tab-title ul li {
    padding-left: 20px;
    display: inline-block;

}

.detail-tab-title ul li a {
    font-family: $font4;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    color: #FFF;
    background: #232323;
    padding: 8px 15px;
    border-radius: 4px 4px 0px 0px;
    display: inline-block;
}

.detail-tab-title ul li.active a {
    background: #CC0000;
}

.detail-tab-title ul li a:hover,
.detail-tab-title ul li a:focus {
    text-decoration: none;
    background: #CC0000;
}

.detail-tab-content {
    border-radius: 0px 4px 4px 4px;
    border: 1px solid #CCC;
    padding: 10px 12px;
}

.detail-tab-content .detail-tab-desc > * {
    margin-bottom: 0;
}

.product-detail-variant-label > span {
    display: inline-block;
    border-radius: 4px 4px 0px 0px;
    background: #232323;
    color: #FFF;
    font-family: $font4;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    padding: 8px 15px;
    width: 100%;
}

.product-detail-variant-select-inner {
    padding: 12px;
    border-radius: 0px 0px 4px 4px;
    border: 1px solid #CCC;
}
.product-detail-variant-check-inner {
    border-radius: 0px 0px 4px 4px;
    border: 1px solid #CCC;
}

.product-detail-variant-select select {
    border-radius: 4px;
    background: #F3F3F3;
    width: 100%;
    color: #000;
    font-family: $font2;
    font-size: 16px;
    font-style: italic;
    font-weight: 300;
    line-height: normal;
    border: none;
    padding: 10px 12px;
}

.product-detail-variant-select select#color, select#country, select#shipping_country {
    appearance: none;
}

.product-detail-variant-select-inner {
    position: relative;
}
.product-detail-variant-select-inner::after {
    content: '\f078';
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    pointer-events: none;
    color: #CC0000;
    font-family: "Font Awesome 5 Pro";
    font-size: 19px;
}

.product-detail-variant-check input {
    appearance: checkbox;
    -webkit-appearance: checkbox;
    -moz-appearance: checkbox;
    -ms-appearance: checkbox;
}

.product-detail-variant-check input[type="radio"] + label:before {
    content: "\F0C8"
}

.product-detail-variant-check input[type=radio]:checked + label:before {
    content: "\F14A";
}

.product-detail-variant-check-inner > div:hover,
.product-detail-variant-check-inner > div:focus {
    background: #F3F3F3;
}

.product-detail-variant-check-inner label {
    margin-bottom: 0px;
}

.product-detail-variant-check-inner > div {
    padding: 10px 12px;
    /*cursor: pointer;*/
}

.product-total-label {
    color: #232323;
    font-family: $font4;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    text-transform: uppercase;
}

.product-total-price {
    color: #CC0000;
    font-family: $font5;
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: 28px;
}

@media (min-width: 768px) {
    #product-detail .product-main-img .col-md-10 {
        padding-left: 30px;
    }
}


@media (max-width: 767px) {
    .lightgallerydiv, .product-main-img .main-image {
        height: auto;
    }

    .main-image img {
        max-height: 300px;
    }

}
