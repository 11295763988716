
#eigen-ontwerp-1 {
    background-color: #f2c1a1;
    position: relative;
    height: 700px;
    color: #fff;
    z-index: 0;
    overflow: hidden;
}


#eigen-ontwerp-1::before {
    position: absolute;
    z-index: -1;
    width: 57%;
    height: 100%;
    bottom: -90px;
    left: -120px;
    color: #cbc9da;
    content: "";
    background-image: url('/images/ontwerp-w.svg');
    background-size: 100%;
    background-position: 0% 0%;
    background-repeat: no-repeat;
    opacity: 1;
    max-width: 822px;
    max-height: 681px;
}

#eigen-ontwerp-1 > .container {
    height: 100%;
}


#eigen-ontwerp-1 h1 {
    color: #FFF;
}

#eigen-ontwerp-1 h1 + p {
    font-family: $main-font;
    font-weight: 900;
    font-size: 25px;
    line-height: 35px;
}

#eigen-ontwerp-1 p, #eigen-ontwerp-1 ul {
    max-width: 580px;
}

#eigen-ontwerp-1 ul li {

    font-family: $main-font;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;

}

#eigen-ontwerp-1 .filled-image {
    position: absolute;
    height: 700px;
    right: 0px;
    padding-left: 0;
    padding-right: 0;
    top: 0;
}


#eigen-ontwerp-2 {
    background-color: #C5D8D6;
    position: relative;
    height: 600px;
    color: #464949;
    z-index: 0;
    overflow: hidden;
}


#eigen-ontwerp-2::before {
    position: absolute;
    z-index: -1;
    width: 45%;
    height: 90%;
    bottom: -75px;
    right: -33px;
    color: #8BA9AD;
    content: "";
    background-image: url('/images/ontwerp-w2.svg');
    background-size: 100%;
    background-position: bottom right;
    background-repeat: no-repeat;
    opacity: 1;
}

#eigen-ontwerp-2 .col-lg-7 {

    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;

}


#eigen-ontwerp-2 > .container {
    height: 100%;
}

#eigen-ontwerp-2 h2 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;
    color: #464949;
}

#eigen-ontwerp-2 p, #eigen-ontwerp-2 ul {
    max-width: 580px;
}

#eigen-ontwerp-2 ul li, #eigen-ontwerp-2 ul li strong {

    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;

}

#eigen-ontwerp-2 ul li strong {
    font-weight: 700;
}

#eigen-ontwerp-2 .filled-image {

    position: absolute;
    height: 600px;
    left: 0px;
    padding-left: 0;
    padding-right: 0;
    top: 0;

}


#eigen-ontwerp-2 a {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    text-decoration-line: underline;
    text-underline-position: under;
    color: #464949;
}

#eigen-ontwerp-3 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

.eigen-ontwerp-3-title {
    text-align: center;
}

#eigen-ontwerp-3 h2 {
    display: inline-block;
    margin: 0 auto;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    font-family: $main-font;
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    text-transform: uppercase;
    color: $black;
}

#eigen-ontwerp-3 h2::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: -10px;
    width: calc(100% + 10px);
    height: 3px;
    background-image: linear-gradient(to right, transparent 50%, #f2c1a1 50%);
    background-size: 22px 100%;
}

.let-op-het-volgende-topics  {
    text-align: center;
    padding-top: 70px;
    padding-bottom: 40px;
}

.let-op-het-volgende-topics > .col-12 {
    max-width: 214px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    padding-top: 20px;
    padding-bottom: 10px;
}

.let-op-het-volgende-topics > .active {
    background: #F1F0F6;
}




#eigen-ontwerp-3 #p1,
#eigen-ontwerp-3 #p2,
#eigen-ontwerp-3 #p3,
#eigen-ontwerp-3 #p4 {
    display: none;
}

#eigen-ontwerp-4 {
    background-color: #F1F0F6;
    height: 240px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    position: relative;
    z-index: 0;
}


#eigen-ontwerp-4::before {
    position: absolute;
    z-index: 1;
    width: 40%;
    height: 100%;
    bottom: 0;
    left: 40px;
    color: #cbc9da;
    content: "";
    background-image: url("/images/ontwerp-w3.svg");
    background-size: 100%;
    background-position: 0% 0%;
    background-repeat: no-repeat;
    opacity: 0.2;
}

#eigen-ontwerp-4 h3 {
    font: {
        family: $main-font;
        weight: 400;
        size: 50px;
    }
    line-height: 70px;
    text-transform: uppercase;
    color: #000;
    margin-bottom: 0;
}


#eigen-ontwerp-4 h3 span {
    font: {
        family: $main-font;
        weight: 700;
    }
}


#eigen-ontwerp-4 p {
    margin-bottom: 0;
}


#eigen-ontwerp-4 a {

    margin: 0;
    border: none;
    overflow: visible;
    font: inherit;
    color: #FFF;
    text-transform: none;
    -webkit-appearance: none;
    border-radius: 0;
    display: inline-block;
    box-sizing: border-box;
    padding: 0 30px;
    vertical-align: middle;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    text-decoration: none;
    background-color: $main-color;
    box-shadow: 0 0 15px $pastel-3-accent;
    border-radius: 5px;
    font-size: 18px;
    padding-top: 7px;
    padding-bottom: 7px;
    transition: all 300ms ease-in-out;
    width: 100% !important;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}


#eigen-ontwerp-4 a:hover,
#eigen-ontwerp-4 a:focus {
    background-color: $pastel-3-accent;
    box-shadow: 0 0 15px $main-color;
}












.img-replace {
    /* replace text with an image */
    display: inline-block;
    overflow: hidden;
    text-indent: 100%;
    color: transparent;
    white-space: nowrap;
}
.bts-popup {
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s;
    -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s;
    transition: opacity 0.3s 0s, visibility 0s 0.3s;
}
.bts-popup.is-visible {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 0.3s 0s, visibility 0s 0s;
    -moz-transition: opacity 0.3s 0s, visibility 0s 0s;
    transition: opacity 0.3s 0s, visibility 0s 0s;
}

.bts-popup-container {
    position: relative;
    width: 90%;
    max-width: 1164px;
    margin: 4em auto;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.1);
    border-radius: none;
    text-align: center;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
    /* Force Hardware Acceleration in WebKit */
    -webkit-backface-visibility: hidden;
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    transition-duration: 0.3s;
    text-align: left;
}
.bts-popup-container img {
    padding: 20px 0 0 0;
}

.bts-popup-container h2 {
    font-family: $main-font;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;
    padding-top: 70px;
    padding-left: 40px;
    padding-right: 40px;
    color: #50676A;
}

.bts-popup-container p {
    font-family: $main-font;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;

    color: #272727;
    padding: 10px 40px;
}

#eigen-ontwerp-2 .bts-popup-container p {
    max-width: none;
}

.bts-popup-container .bts-popup-button {
    padding: 5px 25px;
    border: 2px solid white;
    display: inline-block;
    margin-bottom: 10px;
}

.bts-popup-container a {
    color: white;
    text-decoration: none;
    text-transform: uppercase;
}






.bts-popup-container .bts-popup-close {
    position: absolute;
    top: -14px;
    right: -14px;
    width: 30px;
    height: 30px;
    background: #FFF;
    box-shadow: 0px 0px 9px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
}
#eigen-ontwerp-2 .bts-popup-container .bts-popup-close::before, #eigen-ontwerp-2 .bts-popup-container .bts-popup-close::after {
    content: '';
    position: absolute;
    top: 12px;
    width: 16px;
    height: 3px;
    background-color: #000;
}
.bts-popup-container .bts-popup-close::before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    left: 8px;
}
.bts-popup-container .bts-popup-close::after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    right: 6px;
    top: 13px;
}
.is-visible .bts-popup-container {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}
@media only screen and (min-width: 1170px) {
    .bts-popup-container {
        margin: 8em auto;
    }
}









#eigen-ontwerp-1 .filled-image > div, #eigen-ontwerp-1 .filled-image > div *,
#eigen-ontwerp-2 .filled-image > div, #eigen-ontwerp-2 .filled-image > div * {
    height: 100%;
}

#eigen-ontwerp-1 .filled-image img,
#eigen-ontwerp-2 .filled-image img {
    object-fit: cover;
}









@media (max-width: 991px) {

    #eigen-ontwerp-1 > .container,
    #eigen-ontwerp-2 > .container {
        max-width: none;
    }

    #eigen-ontwerp-1 p, #eigen-ontwerp-1 ul,
    #eigen-ontwerp-2 p, #eigen-ontwerp-2 ul {
        max-width: none !important;
    }

    #eigen-ontwerp-1,
    #eigen-ontwerp-2 {
        height: auto;
    }

    #eigen-ontwerp-1 .filled-image,
    #eigen-ontwerp-2 .filled-image {
        position: relative;
        height: auto;

    }

    #eigen-ontwerp-1 .col-lg-7,
    #eigen-ontwerp-2 .col-lg-7 {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    #eigen-ontwerp-2 .col-lg-5 {
        order: 2;-webkit-order: 2;
    }

    #eigen-ontwerp-2 .col-lg-7 {
        order: 1;-webkit-order: 1;
    }

    #eigen-ontwerp-4 {
        text-align: center;
    }

    #eigen-ontwerp-4 > div > div > div:nth-child(1) {
        padding-bottom: 40px;
    }

}



@media (max-width: 500px) {
    #eigen-ontwerp-4 {
        height: auto;
        padding-top: 40px;
        padding-bottom: 40px;
    }
}
