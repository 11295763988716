.usps ul {
    list-style: none;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    padding-left: 0px;
    margin-right: -55px;
}

.usps ul li {
    position: relative;
    padding-right: 55px;
}

.usps ul li:nth-child(1) {
    padding-left: 65px;
}

.usps ul li:nth-child(2),
.usps ul li:nth-child(3) {
    padding-left: 59px;
}

.usps ul li:nth-child(1)::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: -11px;
    left: 0;
    width: 45px;
    height: 45px;
    background-size: contain;
    background: url('/images/delivery-icon.svg') no-repeat 50% 0%;
}

.usps ul li:nth-child(2)::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: -9px;
    left: 0;
    width: 39px;
    height: 39px;
    background-size: contain;
    background: url('/images/quality-icon.svg') no-repeat 50% 0%;
}

.usps ul li:nth-child(3)::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: -9px;
    left: 0;
    width: 39px;
    height: 39px;
    background-size: contain;
    background: url('/images/personalised-icon.svg') no-repeat 50% 0%;
}



@media (max-width: 600px) {
    .usps ul {
        margin-right: 0px;
    }
    .usps ul li {
        padding-right: 0px;
    }
}
