


//$main-font: aviano-sans, sans-serif;
$main-font: 'Roboto', sans-serif;
$main-font1: aviano-sans, sans-serif;
//$main-font2: aviano-serif, serif;
$main-font2: 'bagnardregular', serif;
$main-font3: calibri, sans-serif;
//$main-font4: 'Allison', cursive;
$main-font4: 'Conv_Renatta_Signature', cursive;
$bold-font:  'Caladea', serif;
$h-font: 'Roboto', sans-serif;
$hand-font: 'Dream  Catcher Regular', serif;

$font1: 'lato-regular', sans-serif;
$font2: 'lato-light', sans-serif;
$font3: 'lato-lightitalic', sans-serif;
$font4: 'lato-bold', sans-serif;
$font5: 'lato-black', sans-serif;


.handwriting {
    font-family: $hand-font;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-search:before {
    content: "\e900";
    transform: translateY(2px);
    display: inline-block;
}
.icon-user:before {
    content: "\e901";
    transform: translateY(2px);
    display: inline-block;
}
.icon-shopping-cart:before {
    content: "\e902";
    transform: translateY(2px);
    display: inline-block;
}
.icon-heart:before {
    content: "\e903";
    transform: translateY(2px);
    display: inline-block;
}


body p {
    font: {
        /*family: $font1;
        weight: 400;*/
        family: $font2;
        weight: 300;
        size: 18px;
    }
    line-height: 28px;
}

body p strong {
    font: {
        family: $main-font;
        weight: 700;
        size: 20px;
    }
    line-height: 28px;
}

h1, h2, h3, h4, h5, h6 {
    font: {
        family: $h-font;
        weight:600;
    }

}
body .uk-h1, body h1 {

    font: {
        family: $main-font;
        weight: 900;
        size: 48px;
    }
    line-height: 56px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}


body .uk-h2, body h2 {
    color: #121212;
    font-family: $font2;
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    /*line-height: normal;*/
    line-height: 36px;
    letter-spacing: 1.5px;
}

body .uk-h2 .bold, body h2 .bold {
    font-family: $font4;
    font-weight: 700;
}


body .white-txt h1 {
    color: $white;
}


/*
strong, b {
    font-family: $bold-font;
}*/



@media (max-width: 767px) {
    body .uk-h1, body h1 {
        font-size: 36px;
        line-height: 44px;
    }
}
