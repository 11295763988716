.login-existing-customer a, .checkout-form .voorwaarden-link {
    color: #121212;
    text-decoration: underline;
    text-underline-position: under;
}

.login-existing-customer a:hover,
.login-existing-customer a:focus,
.checkout-form .voorwaarden-link:hover,
.checkout-form .voorwaarden-link:focus {
    color: #CC0000;
}

.sp-checkout-section .checkout-form {
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 0px !important;
    background: rgba(204,204,204, 0.2);
    border-radius: 4px;
    overflow: hidden;
}


.checkout-page-form .cart-totals-html > div > .row {
    margin-left: 0px;
    margin-right: 0px;
}


.checkout-page-form .checkout-form select {
    border: 1px solid #C4C4C4;
    background-color: transparent;
    height: auto !important;
    min-height: 38px;
    border-radius: 4px !important;
}


.sp-checkout-section .shipping-remarks-row .checkout-form {
    padding-bottom: 8px !important;
}


.checkout-page-form .payment-form > div > div > div {
    padding-left: 0px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}


.checkout-form .butn-next {

    background-color: #CC0000;
    border-color: #CC0000;
    /*box-shadow: 0px 0px 15px #89A6AA;*/
    box-shadow: none;
    border-radius: 4px;
    font-family: $font1;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    padding: 13px 28px;
    color: #FFF;

}


.checkout-form .butn-next:hover,
.checkout-form .butn-next:focus {
    background-color: #121212;
    border-color: #121212;
    /* box-shadow: 0px 0px 15px #89A6AA; */
    color: #fff;
}

.checkout-form .butn-light {

    color: #121212;
    text-underline-position: under;

}

.checkout-form .butn-light:hover,
.checkout-form .butn-light:focus {
    color: #CC0000;
}

.sp-checkout-section .account-form {
    padding-bottom: 20px !important;
}

.checkout-page-form {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}



:focus-visible {
    outline: none !important;
}

.checkout-section {
    padding-bottom: 105px;
}

.confirm-section > div {
    max-width: 1230px;
}

.confirm-section {
    padding-bottom: 135px;
}


.upload-logo {}

@media (max-width: 1199px) {
    .checkout-page-form {
        max-width: none;
    }

    .checkout-page-form .cart-totals-html {
        margin-left: 0;
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

}


.checkout-section > div,
.checkout-section > .sp-checkout-section {
    max-width: 1230px;
    margin-left: auto;
    margin-right: auto;
}

.upload-file-button {
    margin-bottom: 0 !important;
}

.upload-file-button input[type="file"] {
    display: none;
}

.upload-file-button .custom-file-input {
    background-color: #CC0000;
    border-color: #CC0000;
    box-shadow: none;
    border-radius: 4px;
    font-family: $font1;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    padding: 13px 28px;
    color: #FFF;
    opacity: 1;
    width: auto;
    display: block;
    height: auto;
    text-align: center;
    cursor: pointer;
}


.upload-file-button .custom-file-input:hover {
    background-color: #121212;
    border-color: #121212;
    color: #fff;
}


@media (max-width: 991px) {
    .checkout-page-form .cart-totals-html-mobile {
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .checkout-page-form .cart-totals-html-mobile > div {
        padding-left: 0px !important;
    }
}
