
#send-design-1 {
    background-color: #f2c1a1;
    position: relative;
    height: 900px;
    color: #fff;
    z-index: 0;
    overflow: hidden;
}


#send-design-1::before {
    position: absolute;
    z-index: -1;
    width: 57%;
    height: 100%;
    bottom: -90px;
    left: -120px;
    color: #cbc9da;
    content: "";
    background-image: url('/images/ontwerp-w.svg');
    background-size: 100%;
    background-position: 0% 0%;
    background-repeat: no-repeat;
    opacity: 1;
    max-width: 822px;
    max-height: 681px;
}

#send-design-1 > .container {
    height: 100%;
}


#send-design-1 h1 {
    color: #FFF;
}


#send-design-1 .filled-image {
    position: absolute;
    height: 900px;
    right: 0px;
    padding-left: 0;
    padding-right: 0;
    top: 0;
}

#send-design-1 .filled-image > div, #send-design-1 .filled-image > div * {
    height: 100%;
}

#send-design-1 .filled-image img {
    object-fit: cover;
}







.send-design-section1 .leadix-form input:not([type=checkbox]):not([type=radio]) {
    height: 40px;
    border: 1px solid #FFF;
    font-family: $main-font;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
}

.send-design-section1 .leadix-form textarea {
    font-family: $main-font;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    border: 1px solid #FFF;
    background: transparent;
    resize: none;
}

.send-design-section1 .leadix-form input:not([type=checkbox]):not([type=radio]):focus,
.send-design-section1 .leadix-form textarea:focus {
    background-color: #FFF;
    color: #000;
}

.send-design-section1 #form-group-required-privacy-notice .privacy-notice-paragraph a {
    color: #000;
}

.send-design-section1 #form-group-required-privacy-notice .privacy-notice-paragraph a:hover,
.send-design-section1 #form-group-required-privacy-notice .privacy-notice-paragraph a:focus {
    color: #FFF;
}

.send-design-section1 #form-group-required-privacy-notice .privacy-notice-paragraph::before {
    display: none;
}

.send-design-section1 .submit {
    margin: 0;
    border: none;
    overflow: visible;
    font: inherit;
    color: inherit;
    text-transform: none;
    -webkit-appearance: none;
    border-radius: 0;
    display: inline-block;
    box-sizing: border-box;
    padding: 0 30px;
    vertical-align: middle;
    font-size: .875rem;
    line-height: 38px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: .1s ease-in-out;
    transition-property: color,background-color,border-color;
    cursor: pointer;
    width: 271px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 7px;
    background-color: #FFF;
    transition: all 300ms ease-in-out;
    font-size: 18px;
    color: #333;
    /*border: 1px solid #e5e5e5;*/
    border: 1px solid #50676A;
    background-color: #50676A;
    border-color: #50676A;
    box-shadow: 0px 0px 15px #89A6AA;
    color: #FFF;
    margin-top: 20px;
}

.send-design-section1 .submit:hover,
.send-design-section1 .submit:focus {
    /*background-color: #5c6a75;
    color: #FFF;*/
    background-color: #87a4a8;
    border: 1px solid #87a4a8;
    color: $white;
}



.page-ontwerp-insturen input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #FFF;
}
.page-ontwerp-insturen input::-moz-placeholder { /* Firefox 19+ */
    color: #FFF;
}
.page-ontwerp-insturen input:-ms-input-placeholder { /* IE 10+ */
    color: #FFF;
}
.page-ontwerp-insturen input:-moz-placeholder { /* Firefox 18- */
    color: #FFF;
}

.page-ontwerp-insturen .custom-file button[data-v-12a9a2a6] {
    color: #FFF;
}



.page-ontwerp-insturen input.validated::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #000;
}
.page-ontwerp-insturen input.validated::-moz-placeholder { /* Firefox 19+ */
    color: #000;
}
.page-ontwerp-insturen input.validated:-ms-input-placeholder { /* IE 10+ */
    color: #000;
}
.page-ontwerp-insturen input.validated:-moz-placeholder { /* Firefox 18- */
    color: #000;
}

.page-ontwerp-insturen .custom-file .validated + div button[data-v-12a9a2a6] {
    color: #000;
}











@media (min-width: 768px) {
    #form-group-text-1685015896737 {
        width: 49%;
        margin-right: 1%;
        float: left;
    }

    #form-group-text-1685015892079 {
        width: 49%;
        margin-left: 1%;
        float: left;
    }
}





@media (max-width: 991px) {

    #send-design-1 > .container {
        max-width: none;
    }

    #send-design-1 > div > div > div:nth-child(1) {
        order: 2; -webkit-order: 2;
    }

    #send-design-1 > div > div > div:nth-child(2) {
        order: 1; -webkit-order: 1;
    }

    #send-design-1 {
        height: auto;
    }

    #send-design-1 .filled-image {
        position: relative;
        height: auto;

    }

    #send-design-1 .col-lg-7 {
        padding-top: 40px;
        padding-bottom: 40px;
    }



}
