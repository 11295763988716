
.contact-main {
    padding-top: 165px;
    padding-bottom: 95px;
}

#contact-1 {
    background-color: #f2c1a1;
    position: relative;
    height: 900px;
    color: #fff;
    z-index: 0;
    overflow: hidden;
}


#contact-1::before {
    position: absolute;
    z-index: -1;
    width: 57%;
    height: 100%;
    bottom: -90px;
    left: -120px;
    color: #cbc9da;
    content: "";
    background-image: url('/images/ontwerp-w.svg');
    background-size: 100%;
    background-position: 0% 0%;
    background-repeat: no-repeat;
    opacity: 1;
    max-width: 822px;
    max-height: 681px;
}

#contact-1 > .container {
    height: 100%;
}


#contact-1 h1 {
    color: #FFF;
}


#contact-1 .filled-image {
    position: absolute;
    height: 900px;
    right: 0px;
    padding-left: 0;
    padding-right: 0;
    top: 0;
}

#contact-1 .filled-image > div, #contact-1 .filled-image > div *,
#contact-2 .filled-image > div, #contact-2 .filled-image > div * {
    height: 100%;
}

#contact-1 .filled-image img,
#contact-2 .filled-image img {
    object-fit: cover;
}







.contact-section1 .leadix-form input:not([type=checkbox]):not([type=radio]) {
    height: 40px;
    border: 1px solid #FFF;
    font-family: $main-font;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
}

.contact-section1 .leadix-form textarea {
    font-family: $main-font;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    border: 1px solid #FFF;
    background: transparent;
    resize: none;
}

.contact-section1 .leadix-form input:not([type=checkbox]):not([type=radio]):focus,
.contact-section1 .leadix-form textarea:focus {
    background-color: #FFF;
    color: #000;
}

.contact-section1 #form-group-required-privacy-notice .privacy-notice-paragraph a {
    color: #000;
}

.contact-section1 #form-group-required-privacy-notice .privacy-notice-paragraph a:hover,
.contact-section1 #form-group-required-privacy-notice .privacy-notice-paragraph a:focus {
    color: #FFF;
}

.contact-section1 #form-group-required-privacy-notice .privacy-notice-paragraph::before {
    display: none;
}

.contact-section1 .submit {
    margin: 0;
    border: none;
    overflow: visible;
    font: inherit;
    color: inherit;
    text-transform: none;
    -webkit-appearance: none;
    border-radius: 0;
    display: inline-block;
    box-sizing: border-box;
    padding: 0 30px;
    vertical-align: middle;
    font-size: .875rem;
    line-height: 38px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: .1s ease-in-out;
    transition-property: color,background-color,border-color;
    cursor: pointer;
    width: 271px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 7px;
    background-color: #FFF;
    transition: all 300ms ease-in-out;
    font-size: 18px;
    color: #333;
    /*border: 1px solid #e5e5e5;*/
    border: 1px solid #50676A;
    background-color: #50676A;
    border-color: #50676A;
    box-shadow: 0px 0px 15px #89A6AA;
    color: #FFF;
    margin-top: 20px;
}

.contact-section1 .submit:hover,
.contact-section1 .submit:focus {
    /*background-color: #5c6a75;
    color: #FFF;*/
    background-color: #87a4a8;
    border: 1px solid #87a4a8;
    color: $white;
}







#contact-2 {
    background-color: #C5D8D6;
    position: relative;
    height: 600px;
    color: #464949;
    z-index: 0;
    overflow: hidden;
}


#contact-2::before {
    position: absolute;
    z-index: -1;
    width: 45%;
    height: 90%;
    bottom: -75px;
    right: -33px;
    color: #8BA9AD;
    content: "";
    background-image: url('/images/ontwerp-w2.svg');
    background-size: 100%;
    background-position: bottom right;
    background-repeat: no-repeat;
    opacity: 1;
}

#contact-2 .col-lg-7 {

    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;

}


#contact-2 > .container {
    height: 100%;
}

#contact-2 h2 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;
    color: #464949;
}

#contact-2 p, #contact-2 ul {
    max-width: 580px;
}




#contact-2 .filled-image {

    position: absolute;
    height: 600px;
    left: 0px;
    padding-left: 0;
    padding-right: 0;
    top: 0;

}


#contact-2 a {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    text-decoration-line: underline;
    text-underline-position: under;
    color: #464949;
}




.contact-main-col2 a {
    color: #121212;
}

.contact-main-col2 a:hover,
.contact-main-col2 a:focus {
    color: #CC0000;
    text-underline-position: under;
}

.contactmap {
    margin-top: 40px;
}

.contact-main-col1 button {
    border-radius: 4px;
    background: #CC0000;
    border: 1px solid #CC0000;
    width: 100%;
    color: #FFF;
    text-align: center;
    font-family: $font4;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: inline-block;
    padding: 10px 40px;
    transition: all 300ms ease-in-out;
}

.contact-main-col1 button:hover,
.contact-main-col1 button:focus {
    background: #121212;
    border: 1px solid #121212;
}

.contact-main-col1 .leadix-form input.required-privacy-notice {
    display: none;
}

.contact-main-col1 .leadix-form .privacy-notice-paragraph {
    position: relative;
    padding-left: 30px;
}

.contact-main-col1 .leadix-form .privacy-notice-paragraph::before {
    position: absolute;
    top: 0;
    left: 0;
}

.contact-main-col1 .leadix-form label:not(.privacy-notice-paragraph) {
    font-size: 18px;
}

.contact-main-col1 .leadix-form .privacy-notice-paragraph a {
    color: #121212;
}


.contact-main-col1 .leadix-form .privacy-notice-paragraph a:hover,
.contact-main-col1 .leadix-form .privacy-notice-paragraph a:focus {
    color: #CC0000;
}

@media (min-width: 768px) {
    #form-group-text-1685015896737 {
        width: 49%;
        margin-right: 1%;
        float: left;
    }

    #form-group-text-1685015892079 {
        width: 49%;
        margin-left: 1%;
        float: left;
    }
}


@media (min-width: 1200px) {
    .contact-main-col2 {
        padding-left: 100px;
    }
}


@media (max-width: 991px) {

    .contact-main {
        padding-bottom: 40px;
    }

    #contact-1 > .container,
    #contact-2 > .container {
        max-width: none;
    }

    #contact-1 > div > div > div:nth-child(1) {
        order: 2; -webkit-order: 2;
    }

    #contact-1 > div > div > div:nth-child(2) {
        order: 1; -webkit-order: 1;
    }

    #contact-1,
    #contact-2 {
        height: auto;
    }

    #contact-1 .filled-image,
    #contact-2 .filled-image {
        position: relative;
        height: auto;

    }

    #contact-1 .col-lg-7,
    #contact-2 .col-lg-7 {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    #contact-2 .col-lg-5 {
        order: 2;-webkit-order: 2;
    }

    #contact-2 .col-lg-7 {
        order: 1;-webkit-order: 1;
    }

}



@media (max-width: 767px) {
    .contact-main-col2 {
        padding-top: 40px;
    }
}
