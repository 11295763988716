
header {
    position: sticky;
    top:0;
    z-index: 1010;
   /* background-color: $beige;*/
    background-color: #FFF;
    /*box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;*/

    @media (min-width: 960px) and (max-width: 1199px) {
        > div:last-child > div > div:nth-child(3) {
            padding-right: 45px;
        }
    }
}

header.header-desktop > div {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
}

.header-general {
    background: #CC0000;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04), 0px 15px 33px 0px rgba(0, 0, 0, 0.04), 0px 61px 61px 0px rgba(0, 0, 0, 0.03), 0px 137px 82px 0px rgba(0, 0, 0, 0.02), 0px 243px 97px 0px rgba(0, 0, 0, 0.01), 0px 380px 106px 0px rgba(0, 0, 0, 0.00);
}

.mini-cart {
    position: relative;
    a, a.visited {
        color: $main-color;
    }
    .badge-main {
        /*background-color: $pastel-2;*/
        background-color: #121212;
        position: absolute;
        right: -14px;
        top: -8px;
        /*color: $black;*/
        color: $white;
        z-index: 999;
    }
}


header .top-menu-icons a:hover,
header .top-menu-icons a:focus {
    text-decoration: none;
}

header .top-menu-icons img, .mobile-menu-block-open-bottom img {
    width: 25px;
    height: 25px;
    //filter: invert(37%) sepia(10%) saturate(829%) hue-rotate(139deg) brightness(97%) contrast(88%);
    /*filter: brightness(0) saturate(100%) invert(35%) sepia(33%) saturate(241%) hue-rotate(139deg) brightness(100%) contrast(91%);*/
    filter: brightness(0) invert(1);
}

header .top-menu-icons img:hover,
header .top-menu-icons img:focus,
.mobile-menu-block-open-bottom img:hover,
.mobile-menu-block-open-bottom img:focus,
.header-desktop-home .mob-right img:hover,
.header-desktop-home .mob-right img:focus {
    cursor: pointer;
    //filter: invert(73%) sepia(18%) saturate(227%) hue-rotate(124deg) brightness(94%) contrast(101%);
    /*filter: brightness(0) saturate(100%) invert(79%) sepia(27%) saturate(177%) hue-rotate(124deg) brightness(92%) contrast(81%);*/
    filter: invert(39%) sepia(87%) saturate(5003%) hue-rotate(2deg) brightness(104%) contrast(105%);
}

header.header-general .top-menu-icons img:hover,
header.header-general .top-menu-icons img:focus,
header.header-general .mob-right img:hover,
header.header-general .mob-right img:focus {
    filter: brightness(0) invert(0);
}

header.header-general .mobile-menu-icon:hover input[type=checkbox] + label span,
header.header-general .mobile-menu-icon:focus input[type=checkbox] + label span {
    background: #000;
}

header.header-desktop-home .mobile-menu-icon:hover input[type=checkbox] + label span,
header.header-desktop-home .mobile-menu-icon:focus input[type=checkbox] + label span {
    background: #CC0000;
}

.mobile-menu-block-open-bottom {
    padding-top: 20px;
    /*position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;*/
    /*position: fixed;
    left: 0;
    right: 0;
    bottom: 30px;*/
}

.mobile-menu-block-open-bottom > * + * {
    margin-left: 20px;
}


.mobile-menu-block-open-bottom *:hover,
.mobile-menu-block-open-bottom *:focus {
    text-decoration: none;
}

.searchpage .searchpage-content .search-field-input {
    width: 80%;
    display: inline-block;
    float: left;
}

.searchpage .searchpage-content .header-search {
    max-width: none;
}


.searchpage .searchpage-content .search-field-input input {
    width: 100%;
    color: #000 !important;
    text-align: left;
    padding: 5px 15px;
    height: 44px;
}


.searchpage .searchpage-content .do-search-button {
    width: 20%;
    display: inline-block;
    float: left;
    height: 44px;
    background-color: $main-color;
    color: #FFF;
    line-height: 44px;
    cursor: pointer;
}


.searchpage .searchpage-content .do-search-button svg {
    float: right;
    position: relative;
    top: 12px;
    right: 15px;
}


.mobile-menu-block-icons {
    margin-top: 40px;
}

.mobile-menu-block-icons .user-login-link {
    margin-right: 20px;
}

.mobile-menu-block-icons .mini-cart {
    margin-left: 20px;
}

.mobile-menu-block-logo {
    position: fixed;
    bottom: 20px;
    left: 15px;
    right: 15px;
    z-index: 999;
}

.mobile-menu-block-logo img {
    object-fit: contain;
}

@media (min-width: 960px) {
    header .container-fluid-mobile, .mobile-menu-block {
        display: none;
    }

    header .top-menu-icons > * + * {
        margin-left: 20px;
    }

}



@media (max-width: 959px) {

    .mob-right .user-login-link {
        margin-right: 20px;
    }

    .menu-mobile-menu > li.has-sub > ul.sub-menu > li.has-sub > ul.sub-menu {
        display: none;
    }

    .menu-mobile-menu > li.has-sub > ul.sub-menu > li.has-sub > ul.sub-menu.open {
        display: block;
    }


    header .container-fluid-mobile .main-nav-container {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    /*header .container-fluid-mobile .main-nav-container,*/
    header .container-fluid-desktop {
        display: none;
    }

    header .container-fluid-mobile {
        padding-left: 20px !important;
        padding-right: 20px !important;
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }

    header .container-fluid-mobile .header-mobile-col8 {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
    }

    .header-desktop .mobile-menu-block {
        opacity: 0;
        position: fixed;
        top: 0;
        left: 0;
        height: 100svh;
        width: 75vw;
        background: #CC0000;
        pointer-events: none;
        transform: translateX(-100%);
        opacity: 0;
        transition: all 300ms ease;
    }

    .header-desktop .mobile-menu-block.open {
        z-index: 9999;
        opacity: 1;
        transform: translateX(0);
        pointer-events: all;
        //box-shadow: 5px 0 5px -5px #aaa;
        box-shadow: 5px 0 5px -5px #222;
    }

    .header-desktop .mobile-menu-block.open .close-menu {
        padding-right: 15px;
        padding-top: 15px;
        text-align: right;
    }

    .header-desktop .mobile-menu-block.open .close-menu svg {
        font-size: 24px;
        color: #FFF;
    }

    .header-desktop .mobile-menu-block.open .menu-mobile-menu {
        list-style: none;
        text-align: left;
    }

    .header-desktop .mobile-menu-block.open .menu-mobile-menu li.has-sub {
        margin-top: 10px;
    }

    .header-desktop .mobile-menu-block.open .menu-mobile-menu .sub-menu {
        list-style: none;
        margin-top: 10px;
    }

    .header-desktop .mobile-menu-block.open .menu-mobile-menu li + li {
        margin-top: 10px;
    }


    .header-desktop .mobile-menu-block.open .menu-mobile-menu > li.has-sub > ul.sub-menu > li.has-sub > ul.sub-menu {
        margin-top: 20px;
        margin-bottom: 20px;
        display: none !important;
    }

    .header-desktop .mobile-menu-block.open .menu-mobile-menu * {
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        font-weight: 400;
        /*color: $main-color;*/
        /*color: #121212;*/
        color: #FFF;
        text-underline-position: under;
    }

    .header-desktop .mobile-menu-block.open .menu-mobile-menu > li.has-sub > a {
        font-size: 16px;
    }

    .header-desktop .mobile-menu-block.open .menu-mobile-menu li.has-sub ul.sub-menu a {
        font-size: 14px;
    }

    .header-desktop .mobile-menu-block.open .menu-mobile-menu *:hover,
    .header-desktop .mobile-menu-block.open .menu-mobile-menu *:focus {
        /*color: $pastel-3-accent;*/
        /*color: #CC0000;*/
        color: #FFF;
    }

}


@media (max-width: 767px) {
    .searchpage .searchpage-content .search-field-input {
        width: 70%;
    }
    .searchpage .searchpage-content .do-search-button {
        width: 30%;
    }
    .logincontainer .butn-login {
        width: 100%;
        margin-bottom: 20px;
    }

    .logincontainer .btn-link {
        padding-left: 0;
        padding-right: 0;
    }
}


@media (min-width: 960px) and (max-width: 991px) {
    header .container-fluid-desktop > div > div.col-md-2 {
        flex: 0 0 20%;
        max-width: 20%;
    }
    header .container-fluid-desktop > div > div.col-md-8 {
        flex: 0 0 60%;
        max-width: 60%;
    }
}


@media (min-width: 660px) and (max-width: 959px) {
    header .top-menu-icons > * + * {
        margin-left: 15px;
    }
    header .container-fluid-mobile > div > div.col-2 {
            flex: 0 0 25%;
            max-width: 25%;
    }
    header .container-fluid-mobile > div > div.col-8 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}




@media (max-width: 1440px) {

    header.header-desktop > div {
        padding-left: 15px !important;
        padding-right: 30px !important;
        max-width: 1230px;
    }

    .home-header-logo > div {
        padding-left: 0px;
    }

}


@media (max-width: 959px) {
    .mob-right {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: flex-end;
        -webkit-justify-content: flex-end;
    }

    .mob-right .mini-cart {
        margin-right: 30px;
    }

    header.header-desktop > div {
        padding-left: 15px !important;
        padding-right: 15px !important;
        max-width: 1230px;
    }

}



@media (max-width: 659px) {
    header .top-menu-icons > *:not(:last-child) {
        margin-bottom: 20px;
    }

    header .top-menu-icons > div {
        display: block !important;
    }

    .mob-right .mini-cart {
        margin-right: 25px;
    }

}


@media (max-width: 480px) {
    .searchpage .searchpage-content .search-field-input {
        width: 100%;
    }
    .searchpage .searchpage-content .do-search-button {
        width: 100%;
    }
}
