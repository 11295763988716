section#home-intro {
    background-color: $pastel-1;
    position: relative;
    height: 700px;
    color: $pastel-1-accent;
    z-index: 0;
    .content-div {
        position: relative;
        z-index: 10;
        font-size: 20px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: 28.13px;
    }
    .container {
        height: 100%;
        color: $black;
    }
    .filled-image {
        position: absolute;
        height: 700px;
        right: 0px;
        padding-left: 0;
        padding-right: 0;
        top: 0;
        >div {
            text-align: right;
        }
        img {
            height: 700px;
            object-fit: cover;
        }
    }
    &:before {
        position: absolute;
        z-index: -1;
        width: 45%;
        height: 80%;
        bottom: 0;
        left: 0;
        color: $pastel-1-accent;
        content: "";
        background-image: url('/images/w.svg');
        background-size: 100%;
        background-position: bottom left;
        background-repeat: no-repeat;
        opacity: .20;
    }
    .uk-button.uk-button-default:hover {
        background-color: #87a4a8;
        color: $white;
    }
}

section#home-about {
    background-color: $pastel-2;
    position: relative;
    height: 600px;
    color: $pastel-1-accent;
    .content-div {
        position: relative;
        z-index: 10;
        font-size: 20px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: 28.13px;
        color:$white;
        h2 {
            font-size: 48px;
            font-weight: 700;
            color: $white;
        }
        .uk-button.uk-button-default:hover {
            /*background-color: #5c6a75;
            color: $white;*/
            background-color: #87a4a8;
            color: $white;
        }

    }
    .container {
        height: 100%;
        color: $black;
    }
    .filled-image {
        position: absolute;
        height: 600px;
        left: 0px;
        padding-left: 0;
        padding-right: 0;
        top: 0;
        >div {
            text-align: left;
        }
        img {
            height: 600px;
            object-fit: cover;
        }
    }
    &:before {
        position: absolute;
        z-index: 1;
        width: 45%;
        height: 90%;
        bottom: 0;
        right: 0;
        color: $pastel-2-accent;
        content: "";
        background-image: url('/images/w2.svg');
        background-size: 100%;
        background-position: bottom right;
        background-repeat: no-repeat;
        opacity: .20;
    }
}


.home-slider-new {
    /*max-width: 96%;*/
    max-width: calc(100% - 96px);
    margin-left: auto;
    margin-right: auto;
}

/*
.home-slider-new .uk-slider-container {
    overflow: visible !important;
}*/

.home-slider-new .uk-slider-items {
    margin-left: 0;
}

.home-slider-new .uk-slider-items > div {
    padding-left: 8px;
    padding-right: 8px;
}

section#new-products {
    @extend .py-5;
    @extend .mb-4;
    .uk-position-small[class*=uk-position-center-left]{

        background-color: $pastel-2;
        color: $white;
        padding: 11px 20px ;
        transform: translateX(-120%) translateY(30px);
        font-size: 25px;

    }
    .uk-position-small[class*=uk-position-center-right] {

        background-color: $pastel-2;
        color: $white;
        padding: 11px 20px ;
        transform: translateX(120%) translateY(30px);
        font-size: 25px;

    }
    .new-content > div:first-of-type {

        text-align: center;
        @extend .mb-5;

    }
    h2 {

        display:inline-block;
        margin: 0 auto;
        text-align: center;
        text-transform: uppercase;
        position: relative;
        //border-bottom: 3px dashed $pastel-2;
        font: {
            family: $main-font;
            weight: 700;
            size: 24px;
        }
        line-height: 34px;
        text-transform: uppercase;
        color: $black;

        &:after {

            content: "";
            position: absolute;
            bottom: -10px;
            left: -10px;
            width: calc(100% + 10px);
            height: 3px;
            background-image: linear-gradient(to right, transparent 50%, $pastel-2 50%);
            background-size: 22px 100%;

        }

    }

    li, div {
        /*padding-top: 40px;*/

        .new-product-item {
            position: relative;
            display: block;
            &:before {

                content: "";
                position: absolute;
                /*top: -30px;*/
                top: calc(-25% + 55px);
                /*left: calc(50% - 50px);*/
                left: calc(25%);

                width: 50%;
                padding-bottom: 24%;
                background-image: url('/images/tape.svg?052e661…');
                background-size: 100%;
                background-position: bottom center;
                z-index: 999;
                background-repeat: no-repeat;
                transform-origin: center;
                opacity: .8;
                display: none !important;
            }

        }
        &:nth-child(2n) .new-product-item:before {

            //left: 50px;
            transform: scaleX(-1);

        }
        &:nth-child(3n) .new-product-item:before {

            //left: 50px;
            transform:  rotate(20deg);

        }
        &:nth-child(4n) .new-product-item:before {

            //left: 50px;
            transform: rotate(-20deg) scaleX(-1);

        }

        img {
            width: 100%;
            height: 310px;
            object-fit: cover;
        }

    }

}


#new-products > div.container {
    padding-left: 0;
    padding-right: 0;
}

#new-products > div.container > div.row {
    margin-left: 0;
    margin-right: 0;
}

.home-slider-new-nav a {
    display: block !important;
    visibility: visible !important;
    background: #f2c1a1;
    padding: 12px 17px;
    color: #FFF !important;
}


section#home-social {
    background-color: $pastel-3;
    background-image: url('/images/plane.svg');
    background-repeat: no-repeat;
    background-size: 350px;
    background-position: 0 -55px ;
    height: 240px;
    @extend .py-4;
    @extend .d-flex;
    @extend .align-items-center;
    h3 {
        font-size: 32px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: normal;
    }
    h4 {
        margin-top: -15px;
        font-size: 32px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
    }

    .col-lg-5 {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
    }

    .social-buttons {
        ul {

            margin-bottom: 0;

            @extend .row;
            @extend .px-0;
            list-style-type: none;
            li {
                @extend .col-5;
                @extend .py-3;
                &:nth-child(3) {
                    @extend .offset-lg-1;
                }
                a {
                    @extend .uk-button;
                    @extend .w-100;
                    background-color: $white;
                    box-shadow: 0 0 15px $pastel-3-accent;
                    border-radius: 5px;
                    font-size: 18px;
                    padding-top: 7px;
                    padding-bottom: 7px;
                    transition: all 300ms ease-in-out;
                    &:hover {
                        background-color: $button-dark;
                        color: $white;
                    }
                    i {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

section#home-designs {
    display: none !important;
    background-color: $pastel-1;
    position: relative;
    height: 600px;
    color: $pastel-1-accent;
    .content-div {
        position: relative;
        z-index: 10;
        font-size: 20px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: 28.13px;
        h2 {
            font-size: 48px;
            font-weight: 700;
        }
        .uk-button.uk-button-default:hover {
            /*background-color: #f7cc49;
            color: $black;*/
            background-color: #87a4a8;
            color: $white;
        }
    }
    .container {
        height: 100%;
        color: $black;
    }
    .filled-image {
        position: absolute;
        height: 600px;
        right: 0px;
        padding-left: 0;
        padding-right: 0;
        top: 0;
        >div {
            text-align: right;
        }
        img {
            height: 600px;
            object-fit: cover;
        }
    }
    &:before {
        position: absolute;
        z-index: 1;
        width: 45%;
        height: 80%;
        bottom: 0;
        left: 0;
        color: $pastel-1-accent;
        content: "";
        background-image: url('/images/w.svg');
        background-size: 100%;
        background-position: bottom left;
        background-repeat: no-repeat;
        opacity: .20;
    }
}




.page-home p, .page-home li {
    max-width: 571px;
}


.page-home .waarom-vouwmeter p {
    max-width: none;
}


.page-home .waarom-vouwmeter .uk-animation-slide-left {
    position: relative;
    z-index: 1;
}

#home-designs h3 {
    font: {
        family: $main-font;
        weight: 700;
        size: 20px;
    }
    line-height: 28px;
    color: $main-color;
    margin-bottom: 0;
}

#home-designs h3 + p {
    margin-top: 0;
}


section#new-products > div.container {
    max-width: none;
}


section#new-products  .new-content {
    padding-left: 0;
    padding-right: 0;

    .container {
        padding-left: 0;
        padding-right: 0;
    }

    .related-products {

        .uk-slider-container {

            .uk-slidenav-previous {
                transform: translateX(40%) translateY(0);
            }

            .uk-slidenav-next {
                transform: translateX(-40%) translateY(0);
            }
        }
    }
}




section#new-products .uk-position-center-left,
section#new-products .uk-position-center-right {
    display: block !important;
}



.home-gamma {
    padding-top: 165px;
    padding-bottom: 105px;
}

.home-gamma > .container,
.errorpage > .container {
    max-width: 1230px;
}

.errorpage {
    background: #F9F8F6;
}

.waarom-vouwmeter {
    background: #F9F8F6;
}

.waarom-vouwmeter > .container {
    max-width: 1230px;
}

.waarom-vouwmeter img {
    max-width: 431px;
    max-height: 288px;
    position: relative;
    top: 40px;
    z-index: 2;
}

.waarom-vouwmeter > div > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}


.home-vouwmeter-img img {
    width: 100%;
    object-fit: cover;
}

.home-vouwmeter-info {
    padding-top: 20px;
}

.home-vouwmeter-info-p1  {
    font-family: $font2;
    font-weight: 300;
    font-size: 22px;
}

.home-vouwmeter-info-p1 span {
    font-family: $font4;
    font-weight: 700;
}

.home-vouwmeter-info-p2 {
    color: #CC0000;
    font-family: $font1;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.home-vouwmeter-info-price {
    color: #121212;
    text-align: right;
    font-family: $font1;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 178.5%;
}

.home-vouwmeter-info-price span {
    display: block;
    color: #121212;
    text-align: right;
    font-family: $font5;
    font-size: 26px;
    font-style: normal;
    font-weight: 800;
    line-height: 178.5%;
}

.home-vouwmeter-cta a {
    border-radius: 4px;
    background: #CC0000;
    padding: 11px 41px 13px 42px;
    justify-content: center;
    align-items: center;
    color: #FFF;
    text-align: center;
    font-family: $font4;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 100%;
    display: inline-block;
    transition: all 300ms ease-in-out;
}

.home-vouwmeter-cta a:hover,
.home-vouwmeter-cta a:focus {
    background: #121212;
    color: #FFF;
    text-decoration: none;
}

.pagination-block {
    margin-left: 0;
    margin-right: 0;
}

.pagination-block > div {
    padding-left: 0;
    padding-right: 0;
}

.pagination-block > div > div {
    margin-left: 0;
    margin-right: 0;
}

.pagination-block > div > div > div {
    padding-left: 0;
    padding-right: 0;
}

.pagination-block pager {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: -15px;
}

@media (max-width: 991px) {

    section#home-social .social-buttons ul {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
    }

    section#home-intro .container,
    section#home-about .container,
    section#home-designs .container {
        max-width: none;
    }

    .page-home p, .page-home li {
        max-width: none;
    }

    section#new-products .uk-position-small[class*=uk-position-center-left],
    section#new-products .uk-position-small[class*=uk-position-center-right] {
        transform: translateX(0%) translateY(30px);
    }


        section#home-intro,
    section#home-about,
    section#home-designs,
    section#home-social {
        height: auto;
    }

    section#home-intro > div > div > div:nth-child(1) {
        order: 2; -webkit-order: 2;
    }

    section#home-intro > div > div > div:nth-child(1), section#home-about .content-div, section#home-designs .content-div {
        padding-top: 40px;
        padding-bottom: 20px;
    }

    section#home-intro > div > div > div:nth-child(2) {
        order: 1; -webkit-order: 1;
    }

    section#home-intro .filled-image,
    section#home-about .filled-image,
    section#home-designs .filled-image {
        position: relative;
        height: auto;
    }

    section#home-intro .filled-image img,
    section#home-about .filled-image img,
    section#home-designs .filled-image img {
        height: auto;
    }

}



@media (min-width: 768px) {
    .home-gamma > div > div > div.col-md-4 {
        flex: 0 0 37.50%;
        max-width: 37.50%;
    }

    .home-gamma > div > div > div.col-md-4 * {
        max-width: 430px;
    }

    .home-gamma > div > div > div.col-md-8 {
        flex: 0 0 62.5%;
        max-width: 62.5%;
    }

    .home-gamma-vouwmeters > div {
        max-width: 700px;
        margin-left: auto;
        margin-right: 0;
    }

    .home-vouwmeter-info .col-md-9 {
        flex: 0 0 66%;
        max-width: 66%;
    }
    .home-vouwmeter-info .col-md-3 {
        flex: 0 0 34%;
        max-width: 34%;
    }

}


@media (max-width: 1199px) {
    .waarom-vouwmeter > .container {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .waarom-vouwmeter img {
        position: relative;
        top: 0px;
    }
}


@media (max-width: 959px) {

    .home-gamma {
        padding-top: 145px;
        padding-bottom: 40px;
    }

    .usps ul {
        list-style: none;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        padding-left: 0px;
        margin-right: -55px;
        margin-bottom: 0px;
    }

    .waarom-vouwmeter > .container {
        padding-bottom: 20px;
    }

}

@media (max-width: 939px) {
    .usps ul li:not(:last-child) {
        padding-bottom: 30px;
    }

    .usps ul li {
        flex: 0 1 auto;
    }
}

@media (min-width: 768px) and (max-width: 1124px) {

    .home-vouwmeter-info-p1 {
        font-size: 20px;
    }

    .home-vouwmeter-info .col-md-9 {
        flex: 0 0 66%;
        max-width: 66%;
    }
    .home-vouwmeter-info .col-md-3 {
        flex: 0 0 34%;
        max-width: 34%;
    }
    .home-vouwmeter-info-p2 {
        font-size: 12px;
    }
    .home-vouwmeter-info-price span {
        font-size: 20px;
    }
}


@media (max-width: 767px) {
    section#home-about .content-div h2,
    section#home-designs .content-div h2 {
        font-size: 36px;
        line-height: 44px;
    }

    section#home-social .social-buttons ul li {
        padding-left: 5px;
        padding-right: 5px;
    }

    section#home-social .social-buttons ul li a {
        padding-left: 10px;
        padding-right: 10px;
    }

    .home-gamma-vouwmeters > div > section > section + section {
        padding-top: 40px;
    }

    .waarom-vouwmeter .col-md-7 {
        padding-top: 40px;
    }

    .home-vouwmeter-info-p1 {
        font-size: 20px;
    }

    .home-vouwmeter-info .col-md-9 {
        flex: 0 0 66%;
        max-width: 66%;
    }
    .home-vouwmeter-info .col-md-3 {
        flex: 0 0 34%;
        max-width: 34%;
    }
    .home-vouwmeter-info-p2 {
        font-size: 12px;
    }
    .home-vouwmeter-info-price span {
        font-size: 20px;
    }

}


@media (max-width: 480px) {
    .home-gamma {
        padding-bottom: 20px;
    }
    .waarom-vouwmeter > .container {
        padding-top: 20px;
    }
}
